import React, { useEffect, useState } from 'react';

export const useIsFullIntersected = (containerRef: React.RefObject<HTMLDivElement>): [boolean] => 
{
    const [ isFullIntersected, setIsFullIntersected ] = useState<boolean>(false);

    useEffect(() => 
    {
        const scrollContainer = containerRef.current;

        if (!scrollContainer) return;

        const observer = new IntersectionObserver(
            (entries) => 
            {
                const entry = entries[0];
                setIsFullIntersected(entry.isIntersecting);
            },
            {
                root: null, // Use the viewport as the container
                threshold: 0.5 // Trigger when 100% of the element is in view
            }
        );

        observer.observe(scrollContainer);

        return () => 
        {
            observer.disconnect();
        };
    }, [ containerRef ]);

    return [ isFullIntersected ];
};