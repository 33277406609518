import React from 'react';
import styled from 'styled-components';
import { MediaQuery, Palette } from 'core/config';
import IMAGES from 'assets/images';

interface Props {
    title: string;
    hasIcon?: boolean;
    iconSrc?: string;
    width?: number;
    height?: number;
    fontSize?: number;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    isVisibleOnMobile?: boolean;
    isVisibleOnDesktop?: boolean;
    className?: string;
    arrowPositionDown?: boolean;
}

export const Button: React.FunctionComponent<Props> = ({ title, hasIcon, width, height, fontSize, onClick, isVisibleOnMobile, isVisibleOnDesktop = true, className, arrowPositionDown, iconSrc, }) => 
{
    return (
        <Container className={className} width={width} height={height} isVisibleOnMobile={isVisibleOnMobile} isVisibleOnDesktop={isVisibleOnDesktop} onClick={onClick}>
            <Text fontSize={fontSize ?? 11}>{title}</Text>
            {hasIcon && <Image src={iconSrc? IMAGES[iconSrc] : IMAGES.buttonArrowIcon} width='38px' height='38px' alt='Button' arrowPositionDown={arrowPositionDown} />}
        </Container>
    );
};

export const Text = styled.div<{ fontSize?: number }>`
    z-index: 1;
    margin-top: 2px;
    padding: 0 16px;
    font-family: 'Poppins-Regular';
    font-size: ${({ fontSize }) => fontSize ? fontSize : 10}px;
    color: ${Palette.BLACK};
    @media ${MediaQuery.tablet} {
        font-size: ${({ fontSize }) => fontSize}px;
    }
`;

export const Image = styled.img<{ src: string, arrowPositionDown?: boolean }>`
    z-index: 1;
    width:  ${({ arrowPositionDown }) => arrowPositionDown ? '18px' : '38px'};
    height: auto;
    filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(0%) hue-rotate(265deg) brightness(95%) contrast(105%); // black arrow

    transform: ${({ arrowPositionDown }) => arrowPositionDown ? 'rotate(90deg)' : 'rotate(0deg)'};
`;

const Container = styled.button<{
    width: number | undefined;
    height: number | undefined;
    isVisibleOnMobile?: boolean;
    isVisibleOnDesktop?: boolean;
}>`
    position: relative;
    width: ${({ width }) => (width ? width : 97)}px;
    height: ${({ height }) => (height ? height : 25)}px;
    background-color: ${Palette.WHITE};
    border: 1px solid ${Palette.BLACK};
    border-radius: 500px;
    float: right;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &:active {
        background-color: ${Palette.BLACK};
        border-color: ${Palette.BLACK};
        ${Text} {
            color: ${Palette.WHITE};
        }
        ${Image} {
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
                hue-rotate(288deg) brightness(102%) contrast(102%); // white arrow
        }
    }

    &:after {
        display: none;
    }

    @media ${MediaQuery.mobileS} {
        display: ${({ isVisibleOnMobile }) => (isVisibleOnMobile ? 'flex' : 'none')};
    }

    @media ${MediaQuery.tablet} {
        display: flex;
        width: ${({ width }) => width}px;
        height: ${({ height }) => height}px;
    transition: all 0.2s ease-in;

        &:after {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: ${Palette.BLACK};
            left: -100%;
            content: "";
            transition: all 0.15s ease-in;
            z-index: 0;
        }
        &:hover::after {
            left: 0%;
        }
        &:hover ${Text} {
            transition-delay: 0.1s;
            color: ${Palette.WHITE};
        }
        &:hover ${Image} {
            transition-delay: 0.15s;
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
                hue-rotate(288deg) brightness(102%) contrast(102%); // white arrow
        }
    }

    @media ${MediaQuery.laptopS} {
        display: ${({ isVisibleOnDesktop }) => (isVisibleOnDesktop ? 'flex' : 'none')};
    }
`;