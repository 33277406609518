import { useEffect, useState } from 'react';

export const useScroll = (scrollElement?: string) : number | undefined =>
{
    const [ scrollY, setScrollY ] = useState<number>();

    useEffect(() => 
    {
        if (scrollElement)
        {
            const el = document.getElementById(scrollElement);
            const event = () => handleScroll(el?.scrollTop);
            el?.addEventListener('scroll', event, true);
            return () => el?.removeEventListener('scroll', event);
        }
        else
        {
            const event = () => handleScroll(window.scrollY);
            window?.addEventListener('scroll', event, true);
            return () => window?.removeEventListener('scroll', event);
        }
    }, []);
    
    const handleScroll = (newScrollY?: number) =>
    {
        // console.log(newScrollY);
        setScrollY(newScrollY);
    };

    return scrollY;
};