import styled from 'styled-components';
import { noop } from '.';

enum TokenType {
    TEXT = 'text',
    LINK = 'link',
    BOLD = 'bold',
}

type Token = {
    type: TokenType;
    value: string;
    href?: string;
};

export const findAndEmbedLinks = (text: string): Array<JSX.Element> => 
{
    const tokens: Array<Token> = [];
    let lookAhead: string;
    let currentToken: Token = {
        type: TokenType.TEXT,
        value: '',
        href: undefined,
    };

    for (let curr = 0; curr < text.length; curr++) 
    {
        const char = text[curr];
        lookAhead = text[curr + 1];

        // Handle bold text wrapped in '**'
        if (text.substr(curr, 2) === '**') 
        {
            if (currentToken.value) 
            {
                tokens.push(currentToken);
            }
            currentToken = currentToken.type === TokenType.BOLD
                ? { type: TokenType.TEXT, value: '' }
                : { type: TokenType.BOLD, value: '' };
            curr += 1; // Skip the '**'
            continue;
        }

        // Handle link parsing
        if (lookAhead) 
        {
            if (typeof currentToken.href === 'string' && char !== '(') 
            {
                currentToken.href += char;
            } 
            else 
            {
                currentToken.value += char;
            }

            if (lookAhead === '[') 
            {
                tokens.push(currentToken);
                currentToken = {
                    type: TokenType.LINK,
                    value: '',
                };
                curr += 1;
            }
            else if (lookAhead === ']' && currentToken.type === TokenType.LINK) 
            {
                currentToken.href = '';
                curr += 2;
            }
            else if (lookAhead === ')' && currentToken.type === TokenType.LINK) 
            {
                tokens.push(currentToken);
                currentToken = {
                    type: TokenType.TEXT,
                    value: '',
                    href: undefined,
                };
                curr += 1;
            }
        }
        else 
        {
            currentToken.value += char;
            tokens.push(currentToken);
        }
    }

    const onRedirect = (href?: string) => 
    {
        return () => 
        {
            if (href) 
            {
                if (href.includes('mailto')) 
                {
                    location.href = href;
                }
                else 
                {
                    window.open(href, '_blank');
                }
            }
        };
    };

    return tokens.map(({ type, value, href }: Token, index: number) => 
    {
        switch (type) 
        {
        case TokenType.LINK:
            return (
                <Span key={`token-${index}`} type={type} onClick={href ? onRedirect(href) : noop}>
                    {value}
                </Span>
            );
        case TokenType.BOLD:
            return <BoldText key={`token-${index}`}>{value}</BoldText>;
        default:
            return <Span key={`token-${index}`} type={type}>{value}</Span>;
        }
    });
};

const Span = styled.span<{ type: TokenType }>`
    ${({ type }) =>
        type === TokenType.LINK &&
        `
        cursor: pointer;
        padding: 0 .1em;
        border-bottom: solid black 2px;
        transition: box-shadow .3s, color .3s;

        :hover {
            color: white;
            box-shadow: inset 0 -3.25em 0 0 black;
        }
    `}
`;
const BoldText = styled.strong`
    color: inherit;
    font-family: 'Poppins-SemiBold';
`;
