import React, { useEffect, useRef } from 'react';

const rotateVec2 = (x : number, y : number, theta : number) => 
{
    const cTheta = Math.cos(theta);
    const sTheta = Math.sin(theta);
  
    const new_x = cTheta*x - sTheta*y;
    const new_y = sTheta*x + cTheta*y;
  
    return [ new_x, new_y ];
};

const computeAB = (x1 : number, y1 : number, x2 : number, y2 : number) : [ number, number ] => 
{
    if (y1 !== 0) 
    {
        const X1 = x1**2;
        const X2 = x2**2;
        const Y1 = y1**2;
        const Y2 = y2**2;

        const r = Y2/Y1;
        const a = (1 - r) / (X2 - X1 * r);
        const b = (1 - X1 * a) / Y1;

        return [ Math.pow(Math.abs(a), -0.5), Math.pow(Math.abs(b), -0.5) ];
    }

    return [ 0, 0 ];
};

export const useRenderSphere = (ref: React.RefObject<HTMLCanvasElement>, renderHeroSection: boolean, colours: Array<string>): void =>
{
    const animateRef = useRef<number>(0);

    useEffect(() => 
    {
        if (ref.current) 
        {
            const canvas = ref.current;
            const ctx = canvas.getContext('2d');

            canvas.width = canvas.clientWidth;
            canvas.height = canvas.clientHeight;
            const drawSphere = () => 
            {
                ctx?.clearRect(0, 0, canvas.width, canvas.height);
                if (ctx) ctx.globalAlpha = 0.5;

                const time = performance.now()/1000;
                const dynamicRadius = canvas.width * 0.5;
                for (let i=0; i<45; i++) 
                {
                    const r = (dynamicRadius * 0.5) + i;
                    const offset = i / 50;
                    const thetaY = time + offset;
                    const thetaX = time + offset;
                    const thetaZ = time / 2;

                    const cThetaY = Math.cos(thetaY);
                    const sThetaY = Math.sin(thetaY);
                    const cThetaX = Math.cos(thetaX);
                    const sThetaX = Math.sin(thetaX);

                    const right_x = r*cThetaY;
                    const right_y = r*sThetaX*sThetaY;

                    const up_x = 0;
                    const up_y = r*cThetaX;

                    const front_x = r*sThetaY;
                    const front_y = -r*sThetaX*cThetaY;

                    const correction_angle = -Math.atan2(front_x, -front_y);

                    const p1 = rotateVec2(right_x, right_y, correction_angle);
                    const p2 = rotateVec2(up_x, up_y, correction_angle);

                    const AB = computeAB(p1[0], p1[1], p2[0], p2[1]);

                    const radiusX = AB[0];
                    const radiusY = AB[1];
                    const rotation = -correction_angle + thetaZ;

                    if (ctx) ctx.strokeStyle = colours[i];

                    ctx?.beginPath();
                    ctx?.ellipse(dynamicRadius, dynamicRadius, radiusX, radiusY, rotation, 0, 2*Math.PI);
                    ctx?.stroke();
                }
                animateRef.current = requestAnimationFrame(drawSphere);
            };

            if(renderHeroSection) 
            {
                animateRef.current = requestAnimationFrame(drawSphere);
            } 
            else 
            {
                cancelAnimationFrame(animateRef.current);
            }
            
            const handleResize = () => 
            {
                canvas.width = canvas.clientWidth;
                canvas.height = canvas.clientHeight;
                animateRef.current = requestAnimationFrame(drawSphere);
            };
            window.addEventListener('resize', handleResize);

            return () => 
            {
                // Cancel animation frame after canvas component unmounts
                cancelAnimationFrame(animateRef.current);
                // Remove resize event listener
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [ ref, renderHeroSection ]);
};