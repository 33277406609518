import React, { useEffect, useRef } from 'react';
import styled, { Keyframes, keyframes } from 'styled-components';
import { MediaQuery } from 'core/config';
import { VIDEOS } from 'assets/video';
import { useIsFullIntersected } from 'view/hooks';


export const Reel : React.FunctionComponent = () =>
{
    const containerRef = useRef(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [ isFullIntersected ] = useIsFullIntersected(containerRef);

    useEffect(() => 
    {
        if (videoRef.current) 
        {
            if (!isFullIntersected) 
            {
                videoRef.current.currentTime = 0;
                videoRef.current.pause();
                
            }
            else 
            {
                videoRef.current.play().catch((err) => 
                {
                    console.error('Error attempting to play', err);
                });
            }
        }
    }, [ isFullIntersected ]);

    return (
        <Container ref={containerRef}>
            <Video 
                ref={videoRef}
                controls={false}
                autoPlay
                playsInline
                muted
                loop
                isScaledDown={!isFullIntersected}
            >
                <source src={VIDEOS.playback} type='video/mp4'/>
            </Video>
        </Container>
    );
};

const Container = styled.div`
    @media ${MediaQuery.mobileS} {
      margin-top: 8rem;
    }
    @media ${MediaQuery.laptopS} {
      margin-top: 0;
    }
`;

export const Video = styled.video<{isScaledDown: boolean}>`
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: transform 1s, border-radius 1s;
    transition-timing-function: ease-in-out;

    transform: scale(${({ isScaledDown }) => isScaledDown ? 0.8 : 1});
    border-radius: ${({ isScaledDown }) => isScaledDown ? 40 : 0}px;
`;

export const Float = (type: string): Keyframes => keyframes`
	0% {
		transform: translateY(${type === 'desktop' ? '-50%' : '0'});
	}
	50% {
		transform: translateY(${type === 'desktop' ? 'calc(-50% + 10px)' : '-20px'});
	}
	100% {
        transform: translateY(${type === 'desktop' ? '-50%' : '0'});
	}
`;
