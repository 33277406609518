import { lazy, Suspense } from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import Route, { RouteProps, RouteAccess } from './Route';
import { MainLayout, BlogLayout, WorkLayout, PressLayout, SplashLayout } from 'view/components/layout';
import { LoadingSpinner } from 'view/components/common/LoadingSpinner';
const BlogPage = lazy(() => import('view/pages/Blog'));
const PressPage = lazy(() => import('view/pages/Press'));
const TeamPage = lazy(() => import('view/pages/Team'));
const LabPage = lazy(() => import('view/pages/Lab'));
const BlogPostPage = lazy(() => import('view/pages/BlogPost'));
const WorkPage = lazy(() => import('view/pages/Work'));
// const CaseStudyPage = lazy(() => import('view/pages/CaseStudy'));
const NewCaseStudyPage = lazy(() => import('view/pages/NewCaseStudy/NewCaseStudy'));
const PasswordPage = lazy(() => import('view/pages/Password'));
const ColorContrastCheckerPage = lazy(() => import('view/pages/ColorContrastChecker'));
const NotFoundPage = lazy(() => import('view/pages/NotFound'));

export enum RoutePath {
    NEW_CASE_STUDY = '/work/:id',
    WORK = '/work',
    TEAM = '/team',
    LAB = '/lab',
    BLOG_POST = '/blog/:id',
    BLOG = '/blog',
    PRESS = '/press',
    PASSWORD = '/password-generator',
    COLOR_CONTRAST_CHECKER = '/color-contrast-checker',
    ROOT = '/',
    NOT_FOUND = '/page-not-found',
}

const routes: Array<RouteProps> = [

    {
        path: RoutePath.COLOR_CONTRAST_CHECKER,
        access: RouteAccess.public,
        layout: MainLayout,
        component: ColorContrastCheckerPage,
    },

    {
        path: RoutePath.PASSWORD,
        access: RouteAccess.public,
        layout: MainLayout,
        component: PasswordPage,
    },
    
    {
        path: RoutePath.NEW_CASE_STUDY,
        access: RouteAccess.public,
        layout: MainLayout,
        component: NewCaseStudyPage,
    },

    {
        path: RoutePath.WORK,
        access: RouteAccess.public,
        layout: WorkLayout,
        component: WorkPage
    },

    {
        path: RoutePath.TEAM,
        access: RouteAccess.public,
        layout: MainLayout,
        component: TeamPage
    },

    {
        path: RoutePath.LAB,
        access: RouteAccess.public,
        layout: MainLayout,
        component: LabPage
    },

    {
        path: RoutePath.BLOG_POST,
        access: RouteAccess.public,
        layout: MainLayout,
        component: BlogPostPage
    },

    {
        path: RoutePath.BLOG,
        access: RouteAccess.public,
        layout: BlogLayout,
        component: BlogPage
    },

    {
        path: RoutePath.PRESS,
        access: RouteAccess.public,
        layout: PressLayout,
        component: PressPage
    },

    {
        path: RoutePath.ROOT,
        access: RouteAccess.public,
        layout: SplashLayout
    },

    {
        path: RoutePath.NOT_FOUND,
        access: RouteAccess.public,
        layout: MainLayout,
        component: NotFoundPage
    },
];

const AppRouter: React.FunctionComponent = () => 
{
    return (
        <Suspense fallback={LoadingSpinner({})}>
            <BrowserRouter>
                <Switch>
                    {
                        routes.map(({ path, access, component, ...props }: RouteProps) => 
                        {
                            return (
                                <Route
                                    key={path}
                                    exact={true}
                                    path={path}
                                    access={access}
                                    component={component}
                                    {...props}
                                />
                            );
                        })
                    }
                    <Redirect from={'*'} to={RoutePath.NOT_FOUND} />
                    <Route exact={false} path={'*'} component={NotFoundPage} access={RouteAccess.public} />
                </Switch>
            </BrowserRouter>
        </Suspense>
    );
};

export default AppRouter;