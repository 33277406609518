import React from 'react';
import { Helmet } from 'react-helmet-async';

interface Props {
    url: string;
}

export const CanonicalTag : React.FunctionComponent<Props> = ({ url }) => 
{
    return (
        <Helmet>
            <link rel='canonical' href={url} />
        </Helmet>
    );
};
