import React, { useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Palette, MediaQuery } from 'core/config';
import { useRenderSphere } from 'view/hooks';
import { SPHERE_COLOURS } from 'core/utilities';
import { useAppContext } from 'core/context';

export const WhatWeDo: React.FunctionComponent = () => 
{
    const { isDesktop } = useAppContext();
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const canvasOutsideRef = useRef<HTMLCanvasElement | null>(null);
    useRenderSphere(canvasRef, true, SPHERE_COLOURS.HOME_PAGE);
    useRenderSphere(canvasOutsideRef, true, SPHERE_COLOURS.HOME_PAGE);

    const handleConnectClick = () => 
    {
        window.open('https://calendly.com/mathewmozaffari', '_blank');
    };

    return (
        <SectionWrapper>
            <Container>
                <InnerContainer className="fade-in">
                    <Content>
                        <Title>{'What We Do'}</Title>
                        <Description>
                            {
                                'Our seasoned developers and designers will integrate within your organization to become an extension of your team.'
                            }
                        </Description>
                        <Description>
                            {
                                'Our experience-based design programming approach allows us to immerse ourselves in your industry, optimizing our support based on your needs while following best practices.'
                            }
                        </Description>
                        <Button onClick={handleConnectClick}>{'Let\'s connect'}</Button>
                    </Content>
                </InnerContainer>
            </Container>
            {!isDesktop && (
                <Canvas
                    ref={canvasOutsideRef}
                    id="sphere-outside"
                    className={'fade-in-sphere'}
                />
            )}
        </SectionWrapper>
    );
};

const fadeIn = keyframes`
    0% { opacity: 0 } 
    100% { opacity: 1 } 
`;

const SectionWrapper = styled.div`
  position: relative;
`;

const Container = styled.div`
  position: static;

  display: flex;
  margin: 0 3em;

  background-color: transparent;
  overflow-y: hidden;

  .fade-in {
    animation: ${fadeIn} 0.5s ease-in;
  }

  @media ${MediaQuery.tablet} {
    margin: 0 5em;
  }

  @media ${MediaQuery.laptopS} {
    width: 100vw;
    height: 100vh;
    margin: 0;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;

  width: 100%;
  height: 100%;
  padding: 3.25em 0 3em 0;

  .fade-in-sphere {
    transition-duration: 0.7s;
    opacity: 1;
  }
  .fade-out-sphere {
    opacity: 0;
  }

  @media ${MediaQuery.mobileS} {
    display: block;
    position: relative;
  }

  @media ${MediaQuery.laptopS} {
    display: flex;
    flex-direction: row;
    padding: 0 5rem;
  }
`;

const Content = styled.div`
  @media ${MediaQuery.laptopS} {
    max-width: 70vw;
  }
`;

const Title = styled.h1`
  width: 100%;

  color: ${Palette.BLACK};

  font-size: calc(1.325em + 4vw);
  line-height: 1.15em;
  white-space: pre-line;

  @media ${MediaQuery.laptopS} {
    width: 40vw;
    font-size: 2.75em;
  }
  @media ${MediaQuery.laptopS} {
    font-size: 3.375em;
  }
  @media ${MediaQuery.desktopS} {
    width: 100%;
    font-size: 4em;
  }
`;

const Description = styled.p`
  width: 100%;

  color: ${Palette.BLACK};
  font-family: "Poppins-Light";

  font-size: 16px;
  line-height: 180%;
  white-space: pre-line;
  padding-top: 24px;

  @media ${MediaQuery.tablet} {
    width: 40vw;
    font-size: 18px;
  }
  @media ${MediaQuery.laptopS} {
    width: 50vw;
    font-size: 20px;
    padding-top: 14px;
  }
  @media ${MediaQuery.desktopS} {
    width: 50vw;
    font-size: 22px;
    padding-top: 14px;
  }
`;

const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;

  width: 12.5em;
  margin-top: 32px;

  border: 2px solid ${Palette.BLACK};
  background-color: ${Palette.BLACK};
  color: ${Palette.WHITE};

  font-family: "Poppins-Light";
  font-size: 12px;

  cursor: pointer;
  transition: opacity 1s ease-in-out;
  border-radius: 50px;
  padding-top: 14px;
  padding-bottom: 14px;

  :hover {
    background-color: ${Palette.WHITE};
    color: ${Palette.BLACK};
  }

  @media ${MediaQuery.tablet} {
    font-family: "Poppins-Regular";
    font-size: 17px;
  }
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 80vw;

  transition: opacity 0.7s ease-in;

  @media ${MediaQuery.mobileS} {
    display: none;
  }

  @media ${MediaQuery.tablet} {
    display: block;
    position: absolute;
    top: 5%;
    right: 50%;
    width: 80vw;
    height: 80vw;
    margin-left: auto;
  }

  @media ${MediaQuery.laptopS} {
    position: relative;
    bottom: 0;
    right: 0;
    width: 50vw;
    height: 50vw;
  }
`;
