import React, { useRef } from 'react';
import styled from 'styled-components';
import Navbar, { SCROLL_BEHAVIOR } from './Navbar';
import Footer from './Footer';
import { CTGCBanner } from '../common/CTGCBanner';

type Props = React.HTMLAttributes<HTMLDivElement>;

export const BlogLayout : React.FunctionComponent<Props> = ({ children }) =>
{
    const ref = useRef<HTMLDivElement>(null);
    return (
        <>
            <Navbar scrollBehavior={{ mobile: SCROLL_BEHAVIOR.OPAQUE_AFTER_SCROLL, desktop: SCROLL_BEHAVIOR.HIDE_ON_SCROLL }} scrollElement='container' />
            <Container id="container" ref={ref}>
                {children}
                <Footer />
            </Container>
            <CTGCBanner />
        </>
    );
};

const Container = styled.div`
    z-index: 0;

    width : 100%;
    height: 100vh;

    -webkit-perspective: 8px;
    -moz-perspective: 8px;
    perspective: 8px;
    perspective-origin: 65% 100%;
    
    overflow-x: hidden;
    overflow-y: scroll;
`;