import React from 'react';
import { StaticContext } from 'react-router';
import { 
    Route as ReactRoute, 
    Redirect, 
    RouteComponentProps, 
    RouteProps 
} from 'react-router-dom';

interface PrivateRouteProps extends RouteProps
{
    isAuth?          : boolean;
    layout?          : React.ComponentType;
    component?       : React.ComponentType;
    publicComponent? : React.ComponentType;
}

type RenderProps = RouteComponentProps<{ [x: string]: string | undefined; }, StaticContext, unknown>;

export const PrivateRoute : React.FunctionComponent<PrivateRouteProps> = ({ 
    isAuth, 
    layout : Layout, 
    component : Component = () => null, 
    publicComponent : PublicComponent,
    ...rest 
}) =>
{
    const render = (props : RenderProps) =>
        (
            isAuth ? (
                Layout === undefined ? (
                    <Component {...props} {...rest} />
                ) : (
                    <Layout>
                        <Component {...props} {...rest} />
                    </Layout>
                )
            ) : (
                PublicComponent === undefined ? (
                    <Redirect to="/" />
                ) : (
                    Layout === undefined ? (
                        <PublicComponent {...props} {...rest} />
                    ) : (
                        <Layout>
                            <PublicComponent {...props} {...rest} />
                        </Layout>
                    )
                )
            )
        );

    return <ReactRoute render={render} />;
};