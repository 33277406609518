import React from 'react';
import styled from 'styled-components';
import { Palette, MediaQuery } from 'core/config';
import { useAppContext } from 'core/context';
import { ImageWrapper } from 'view/components/common';

interface Props 
{
    title            : string;
    subTitle         : string;
    logo?            : {
        url?         : string;
        width?       : number;
        height?      : number;
    }
    colour           : string;
    backgroundColour : string;
    justifyContent?  : string;
    toggleClassName? : string;
    zIndexPos        : number;
    intersected?     : boolean;
    fullView?        : boolean;
}

export const Section : React.FunctionComponent<Props> = ({
    title = '',
    subTitle = '',
    logo,
    colour = Palette.BLACK,
    backgroundColour = Palette.WHITE, 
    justifyContent = 'flexStart',
    toggleClassName,
    zIndexPos,
    intersected,
    fullView = false,
    children
}) => 
{
    const { isDesktop } = useAppContext();

    return (
        <Container colour={backgroundColour} zIndexPos={zIndexPos} isDesktop={isDesktop || false} className={toggleClassName}>
            <InnerContainer justifyContent={justifyContent} paddingRight={title === 'Manzil'} fullView={fullView}>
                <TitleContainer className={intersected ? 'scale-up' : 'scale-down'}>
                    {
                        logo &&
                        <ImageWrapper src={logo?.url}>
                            <Logo 
                                src={logo?.url} 
                                width={logo?.width} height={logo?.height}
                                alt={`${title} logo`} 
                            />
                        </ImageWrapper>
                    }
                    <Subtitle colour={colour}>{subTitle}</Subtitle>
                </TitleContainer>
                <ImagesContent>
                    {children}
                </ImagesContent>
            </InnerContainer>
        </Container>
    );
};

const Container = styled.section<{ colour: string; zIndexPos: number; isDesktop: boolean }>`
    z-index: ${({ isDesktop, zIndexPos }) => (isDesktop && zIndexPos) && zIndexPos};
    background-color: ${({ colour }) => colour};
    overflow: hidden;
    will-change: auto;

    @media ${MediaQuery.laptopS} {
        position: absolute;
    }
`;

const InnerContainer = styled.div<{ justifyContent: string; paddingRight : boolean; fullView: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: ${({ fullView }) => fullView ? '0':'4em 0 4em 0'};
    ${props => props.paddingRight && 'padding-right: 0;'};

    @media ${MediaQuery.mobileS} {
        width: 100%;
        height: 100%;
        padding: ${({ fullView }) => fullView ? '0' : '1em'};
    }

    @media ${MediaQuery.laptopS} {

    }
    @media ${MediaQuery.laptopS} {
                display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: ${({ justifyContent }) => justifyContent === 'flexStart' ? 'flex-start' : 'flex-end'};
        width: 100vw;
        height: 100vh;
        margin: 0;
        padding: 3em 3em;
        padding: ${({ fullView }) => fullView ? '0': '3em 5em'};
        ${props => props.paddingRight && 'padding-right: 0;'};
    }
`;

const TitleContainer = styled.div`
    z-index: 2;
    margin-left: 20px;

    @media ${MediaQuery.laptopS} {
        position: absolute;
        margin-left: 0;
    }
`;

const Logo = styled.img`
`;

const Subtitle = styled.h1<{ colour: string; }>`
    margin-top: 1.125em;

    color: ${({ colour }) => colour};

    font-family: 'Poppins-Medium';
    font-size: calc(1.0875em + 2vw);

    white-space: pre-line;
    line-height: 1.3em;
    
    transform: translateZ(0);

    @media ${MediaQuery.laptopS} {
        font-size: 30px;
    }
    @media ${MediaQuery.laptopS} {
        font-size: 34px;
    }
    @media ${MediaQuery.desktopS} {
        font-size: 44px;
    }
`;

const ImagesContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;