import React, { useRef } from 'react';
import styled from 'styled-components';
import Navbar, { SCROLL_BEHAVIOR } from './Navbar';
import Footer from './Footer';
import { useLocation } from 'react-router';
import { CTGCBanner } from '../common/CTGCBanner';

type Props = React.HTMLAttributes<HTMLDivElement>;

export const MainLayout : React.FunctionComponent<Props> = ({ children }) =>
{
    const ref = useRef<HTMLDivElement>(null);

    const { pathname } = useLocation();
    const path = pathname ? pathname.slice(1) : '';

    return (
        <>
            <Navbar path={path} scrollBehavior={{ mobile: SCROLL_BEHAVIOR.ALWAYS_OPAQUE }} />
            <Container id="container" ref={ref}>
                {children}
                <Footer path={path} />
                <CTGCBanner />
            </Container>
        </>
    );
};

const Container = styled.div`
    position: relative;
    z-index: 0;
    width : 100%;
    height: 100vh;
    /* overflow-x: hidden;
    overflow-y: scroll; */
`;