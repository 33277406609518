import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Palette, MediaQuery } from 'core/config';

import { featuredProjects } from '../data/featuredProjects.json';
import IMAGES from 'assets/images';
import FeaturedAnimatedCard from './FeaturedAnimatedCard';

interface FeaturedProjectsProps {
  navigateToWork?: () => void;
}

export const FeaturedProjects: React.FunctionComponent<
  FeaturedProjectsProps
> = ({ navigateToWork = () => null }) => 
{
    return (
        <Container>
            <InnerContainer>
                <TitleContainer>
                    <Title>{'Featured Projects'}</Title>
                    <Button onClick={() => navigateToWork()}>See All</Button>
                </TitleContainer>

                <FeaturedContainer>
                    {featuredProjects.map((item) => (
                        <FeaturedItem key={item.id} item={item} />
                    ))}
                </FeaturedContainer>
            </InnerContainer>
        </Container>
    );
};

const Container = styled.div`

  padding: 0px 16px;

  @media ${MediaQuery.tablet} {
    padding: 0px 5rem;
  }

  @media ${MediaQuery.laptopL} {
    padding: 0px 7rem;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${MediaQuery.tablet} {
  }
`;

const TitleContainer = styled.div`
  display: none;
  @media ${MediaQuery.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Title = styled.h1`
  color: ${Palette.BLACK};
  font-size: 48px;
  @media ${MediaQuery.laptopS} {
    font-size: 68px;
  }
  
`;

const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;

  border: 2px solid ${Palette.BLACK};
  background-color: ${Palette.BLACK};
  color: ${Palette.WHITE};

  font-family: "Poppins-Light";
  font-size: 12px;

  cursor: pointer;
  transition: opacity 1s ease-in-out;
  border-radius: 50px;
  padding: 10px 20px;

  :hover {
    background-color: ${Palette.WHITE};
    color: ${Palette.BLACK};
  }

  @media ${MediaQuery.laptopS} {
    font-family: "Poppins-Regular";
    font-size: 17px;
    padding: 14px 48px;
  }
`;

const FeaturedContainer = styled.div`
  //background-color: blue;
  padding: 40px 0px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media ${MediaQuery.tablet} {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  @media ${MediaQuery.laptopM} {
    gap: 48px;
  }
`;

const FeaturedItem: React.FC<any> = ({ item }) => 
{
    const history = useHistory();
    const containerRef = useRef<HTMLDivElement>(null);
    const [ isHovered, setIsHovered ] = useState<boolean>(false);
    const [ { x, y }, setMousePosition ] = useState({ x: 0, y: 0 });

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => 
    {
        const container = containerRef.current;
        if (!container) return;
        const bounds = container.getBoundingClientRect();

        const x = event.clientX - bounds.x;
        const y = event.clientY - bounds.y;

        setMousePosition({ x, y });
    };
    
    const onCardClick = (link: string) => 
    {
        if (link.includes('http')) 
        {
            window.open(link, '_blank');
        }
        else 
        {
            if (window.innerWidth < 1024) 
            {
                history.push(link);
            }
            else 
            {
                history.push(`/${link}`);
            }
        }
    };

    return (
        <FeaturedItemWrapper
            key={item.imageKey}
            ref={containerRef}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onMouseMove={handleMouseMove}
            onClick={() => onCardClick(item.url)}>
            <SeeMoreButton>
                <ArrowImg src={IMAGES.arrowUpRight} />
            </SeeMoreButton>

            <HoverButton src={IMAGES.arrowUpRightWhite} isVisible={isHovered} backgroundColor={'black'} x={x} y={y} />

            <FeaturedAnimatedCard item={item} />

            <FeaturedMobileItemContainer>
                <FeaturedImage src={IMAGES[item.imageKey]} />
                <StackContainer>
                    {item.techStack.map((item: string, index: number) => (
                        <TeckStackItem key={item + index}> {item}</TeckStackItem>
                    ))}
                </StackContainer>
                <FeaturedItemTitle>{item.title}</FeaturedItemTitle>
                <FeaturedItemDesc>{item.desc}</FeaturedItemDesc>
            </FeaturedMobileItemContainer>
        </FeaturedItemWrapper>
    );
};

const FeaturedItemWrapper = styled.div`
  position: relative;
  padding-bottom: 40px;
  -webkit-tap-highlight-color: transparent;
`;

const FeaturedMobileItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;

  @media ${MediaQuery.laptopS} {
    display: none;
  }
`;

const FeaturedImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const FeaturedItemTitle = styled.h4`
  color: ${Palette.BLACK};
  font-family: "Poppins-Regular";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const FeaturedItemDesc = styled.p`
  color: ${Palette.BLACK};
  font-family: "Poppins-Light";
  font-size: 12px;
`;

const SeeMoreButton = styled.button`
  position: absolute;
  background-color: ${Palette.WHITE};
  top: 7px;
  right: 7px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: none;
  cursor: pointer;

  @media ${MediaQuery.laptopS} {
    display: none;
  }
`;

const HoverButton = styled.img<{ isVisible: boolean, backgroundColor: string, x: number, y: number}>`
  position: absolute;
  padding: 1rem;
  background-color: black;
  width: 4rem;
  height: 4rem;
  border-radius: 100px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: none;
  cursor: none;
  z-index: 100;
  transform: translate(-50%, -50%) ${({ isVisible, x, y }) => (isVisible ? `translate(${x}px, ${y}px)` : '')};

  @media ${MediaQuery.mobileS} {
    display: none;
  }
  @media ${MediaQuery.laptopS} {
    display: block;
  }
`;

const ArrowImg = styled.img`
  width: 24px;
  height: 24px;
`;

const StackContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const TeckStackItem = styled.p`
  color: ${Palette.BLACK};
  font-family: "Poppins-Light";
  font-size: 12px;
`;
