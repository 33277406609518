import { css, Keyframes, keyframes } from 'styled-components';

export const NoSelect = css`
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select  : none; /* Safari */
     -khtml-user-select  : none; /* Konqueror HTML */
       -moz-user-select  : none; /* Old versions of Firefox */
        -ms-user-select  : none; /* Internet Explorer/Edge */
            user-select  : none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
`;

export const FadeOut = keyframes`
    0% { opacity: 100%; }
    100% { opacity: 0%; }
`;

export const FadeIn = keyframes`
    0% { opacity: 0%; }
    100% { opacity: 100%; }
`;

export const Grow = (direction: number): Keyframes => keyframes`
    0% { transform: scale(${direction}); }
    50% { transform: scale(${direction * 1.5}); }
    100% { transform: scale(${direction}); }
`;

export const SubtleMovement = (forward: boolean): Keyframes => keyframes`
    0% { 
        transform: translateY(${forward ? '50px' : '0px'});
    } 
    100% { 
        transform: translateY(${forward ? '0px' : '50px'});
    } 
`;

export const Float = keyframes`
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
`;