import { useEffect, useState } from 'react';

export enum StatusType
{
    IDLE    = 'idle',
    LOADING = 'load',
    READY   = 'ready',
    ERROR   = 'error'
}

export type Status = StatusType.IDLE
    | StatusType.LOADING
    | StatusType.READY
    | StatusType.ERROR;

export type ScriptElement = HTMLScriptElement | null;

export const useScript = (src : string) : Status =>
{
    const [ status, setStatus ] = useState<Status>(src ? StatusType.LOADING : StatusType.IDLE);

    useEffect(() => 
    {
        if (!src)
        {
            setStatus(StatusType.IDLE);
            return;
        }

        const mapEventToStatus = (event : Event) : Status => 
        {
            return event.type === StatusType.LOADING ? StatusType.READY : StatusType.ERROR;
        };

        // Check that the script hasn't already been appended to the DOM
        let script : ScriptElement = document.querySelector(`script[src="${src}"]`);

        if (!script)
        {
            // Create a new script tag
            script = document.createElement('script');
            script.src = src;
            script.async = true;
            script.setAttribute('data-status', StatusType.LOADING);
            
            // Append to the DOM
            document.body.appendChild(script);

            const setAttributeFromEvent = (event : Event) =>
            {
                script?.setAttribute(
                    'data-status',
                    mapEventToStatus(event)
                );
            };

            script.addEventListener(StatusType.LOADING, setAttributeFromEvent);
            script.addEventListener(StatusType.ERROR, setAttributeFromEvent);
        }
        else
        {
            setStatus(script.getAttribute('data-status') as Status);
        }

        const setStateFromEvent = (event : Event) : void => setStatus(mapEventToStatus(event));

        script.addEventListener(StatusType.LOADING, setStateFromEvent);
        script.addEventListener(StatusType.ERROR, setStateFromEvent);

        return () => 
        {
            if (script)
            {
                script.removeEventListener(StatusType.LOADING, setStateFromEvent);
                script.removeEventListener(StatusType.ERROR, setStateFromEvent);
            }
        };
    }, [ src ]);

    return status;
};