import React from 'react';

export interface AuthState 
{
    id      : string;
    token   : string;
    isAuth  : boolean;
}

export enum AuthType 
{
    REQUEST_LOGIN = 'REQUEST_LOGIN',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGOUT        = 'LOGOUT',
    LOGIN_ERROR   = 'LOGIN_ERROR'
}

export interface AuthPayload
{
    id     : string;
    token  : string; 
    error? : string;
}

export interface AuthAction
{
    type    : AuthType;
    payload : AuthPayload;
}

export interface Props 
{
    initialState : AuthState;
    action       : AuthAction;
}

export const initialState : AuthState = {
    id      : '',
    token   : '',
    isAuth  : false
};

export const AuthReducer: React.Reducer<AuthState, AuthAction> = (initialState, action) => 
{
    switch (action.type) 
    {
    case AuthType.REQUEST_LOGIN:
        return {
            ...initialState,
            loading : true
        };

    case AuthType.LOGIN_SUCCESS:
        return {
            ...initialState,
            id     : action.payload.id,
            token  : action.payload.token,
            isAuth : true,
            error  : undefined
        };

    case AuthType.LOGOUT:
        return {
            ...initialState,
            id     : '',
            token  : '',
            isAuth : false,
            error  : undefined
        };

    case AuthType.LOGIN_ERROR:
        return {
            ...initialState,
            id      : '',
            token   : '',
            isAuth  : false,
            error   : action.payload.error
        };
    }
};