import { MediaQuery, Palette } from 'core/config';
import { useState } from 'react';
import styled from 'styled-components';
import IMAGES from 'assets/images';
import { ReactSVG } from 'react-svg';

export const CTGCBanner: React.FunctionComponent = () => 
{
    const [ isOpen, setIsOpen ] = useState(true);

    const handleClose = () => 
    {
        setIsOpen(false);
    };
    
    return (
        <>
            {isOpen ? (
                <BannerContainer 
                    role='presentation' 
                >
                    <TextContent>
                        <WinnerText>2024 Winner</WinnerText>
                        <TitleText>Canada's Top Growing Companies</TitleText>
                        <LinkButton href='https://www.theglobeandmail.com/business/adv/article-canadas-top-growing-companies-2024-speer-technologies/' target='_blank'>Read Our Story</LinkButton>
                    </TextContent>
                    <BannerImageContainer>
                        <img src={IMAGES.ctgcWinner} alt="2024 Winner - Canada's Top Growing Companies" />
                    </BannerImageContainer>
                    <CloseIconButton onClick={handleClose}>
                        <ReactSVG src={IMAGES.closeBlackIcon} />
                    </CloseIconButton>
                </BannerContainer>
            ) : null}
            <MobileBanner href='https://www.theglobeandmail.com/business/adv/article-canadas-top-growing-companies-2024-speer-technologies/' target='_blank'>
                <MobileBannerImageContainer>
                    <img src={IMAGES.ctgcWinner} alt="2024 Winner - Canada's Top Growing Companies" />
                </MobileBannerImageContainer>
                <MobileBannerTitleText>Canada's Top Growing Companies</MobileBannerTitleText>
                <MobileBannerArrowIcon src={IMAGES.arrowRightV2Icon} />
            </MobileBanner>
        </>
    );
};

const BannerContainer = styled.div`
    display: none;

    @media ${MediaQuery.tablet} {
        display: flex;
        position: fixed;
        right: 24px;
        bottom: 24px;
        display: flex;
        align-items: center;
        gap: 24px;
        max-width: 600px;
        padding: 24px;
        background-color: #EFEAFF;
        box-shadow: 15px 15px 75px 0px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        z-index: 10000;

        :hover {
            background-color: #DBE9FF;
        }
    }
`;

const TextContent = styled.div`
    & > * {
        font-family: 'Poppins-Medium';
        color: ${Palette.BLACK};
    }
`;

const WinnerText = styled.p`
    text-transform: uppercase;
    font-size: 14px;
`;

const TitleText = styled.p`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const LinkButton = styled.a`
    display: inline-block;
    padding: 10px 20px;
    border: 2px solid ${Palette.BLACK};
    border-radius: 50px;
    background-color: ${Palette.BLACK};
    color: ${Palette.WHITE};
    font-size: 12px;
    cursor: pointer;

    :hover {
        background-color: ${Palette.WHITE};
        color: ${Palette.BLACK};
    }
`;

const BannerImageContainer = styled.div`
    img {
        width: 100%;
        max-width: 150px;
    }
`;

const CloseIconButton = styled.button`
    display: flex;
    border: none;
    background-color: transparent;
    cursor: pointer;
`;

const MobileBanner = styled.a`
    position: fixed;
    display: flex;
    align-items: center;
    gap: 12px;
    top: 90px;
    left: 14px;
    right: 14px;
    border-radius: 10px;
    background-color: #EFEAFF;
    padding: 12px;

    @media ${MediaQuery.tablet} {
        display: none;
    }
`;

const MobileBannerImageContainer = styled.div`
    img {
        width: 100%;
        max-width: 83px;
    }
`;

const MobileBannerTitleText = styled.p`
    font-family: 'Poppins-Medium';
    font-size: 12px;
    color: ${Palette.BLACK};
`;

const MobileBannerArrowIcon = styled(ReactSVG)`
    margin-left: auto;
    svg {
        display: block;
    }
`;