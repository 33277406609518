import React from 'react';
import styled from 'styled-components';
import { MediaQuery } from 'core/config';
import { ImageWrapper } from 'view/components/common';

interface Props 
{
    phoneImage     : string;
    popupImage     : string;
    phoneCss?      : string;
    phoneCssLarge? : string;
    popupCss?      : string;
    id             : string;
    intersected    : boolean;
    delay          : number;
}

const Phone : React.FunctionComponent<Props> = ({ 
    phoneImage,
    popupImage, 
    phoneCss, 
    phoneCssLarge, 
    popupCss, 
    intersected, 
    delay,  
    id
}) => 
{
    return (
        <PhoneContainer css={phoneCss} cssLarge={phoneCssLarge} id={id} delay={delay} className={intersected ? 'render-dm-content' : ''}>
            <ImageWrapper src={phoneImage} hasMobileSrc={true}>
                <PhoneImage 
                    src={phoneImage} 
                    alt={'Dealer Methods Phone Preview'} 
                    width='960'
                    height='600'
                />
            </ImageWrapper>
            <PopUpImage src={popupImage} alt={'Dealer Methods Phone Widget'} css={popupCss} />
        </PhoneContainer>
    );
};

const PhoneContainer = styled.div<{ css?: string; cssLarge?: string; delay: number; }>`
    position: relative;
    width: 55%;
    margin-left: 2em;
    opacity: 0; 
    transform: translate(0, 50px) translateZ(0);
    -moz-transform: translate(0, 50px) translateZ(0);
    -webkit-transform: translate(0, 50px) translateZ(0);
    transition: transform 1s ease ${props => props.delay + 's'}, opacity 0.6s ease-in ${props => props.delay - 0.1 + 's'};
    -moz-transition: -moz-transform 1s ease ${props => props.delay + 's'}, opacity 0.6s ease-in ${props => props.delay - 0.1 + 's'};
    -webkit-transition: -webkit-transform 1s ease ${props => props.delay + 's'}, opacity 0.6s ease-in ${props => props.delay - 0.1 + 's'};
    @media ${MediaQuery.desktopS} {
        width: 70%;
        ${props => props.cssLarge && props.cssLarge};
    }

    ${props => props.css && props.css};
`;

const PhoneImage = styled.img`
    position: relative;
    width: 100%;
    height: auto;
`;

const PopUpImage = styled.img<{ css? : string; }>`
    position: absolute;
    left: 37%;
    bottom: 20%;
    width: 18%;
    ${props => props.css && props.css}
`;

export default Phone;