export enum Palette {
    COD_GREY       = '#111111',
    RED            = '#FF0000',
    PACIFIC_BLUE   = '#00AABB',
    BLACK          = '#000000',
    DARK_GRAY      = '#010101',
    WHITE          = '#FFFFFF',
    SALT_PAN       = '#EBEFE0',
    CORN_SILK      = '#FFFBE5',
    SAPPHIRE       = '#173172',
    GREY           = '#7C7C7C',
    BAY_OF_MANY    = '#3D405B',
    LUCKY_POINT    = '#21235A',
    SILVER         = '#BBBBBB',
    GALLERY        = '#ECECEC',
    DOVE_GRAY      = '#707070',
    APPEARANCE     = '#A1A1A1',
    ALABASTER      = '#F8F8F8',
    SILVER_CHALICE = '#ADADAD',
    WILD_SAND      = '#F5F5F5',
    ALTO           = '#D8D8D8',
    DUSTY_GRAY     = '#9A9A9A',
    NAVAJO_WHITE   = '#FFE4AC',
    GRAY_NICKEL    = '#C1C2BE',
    HAWKES_BLUE    = '#D7EDFB',
    ICE_COLD       = '#B1F1E4',
    MULLED_WINE    = '#4D3B6A',
    BEIGE          = '#F4F0DB',
    BON_JOUR       = '#F2F0F2',
    CYAN_BLUE      = '#1D77FF',
    
}