import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import IMAGES from 'assets/images';
import { Palette, MediaQuery } from 'core/config';
import { socialMedias } from './data/footer.json';

interface Props {
  isFullHeight?: boolean;
  path?: string;
}

const Footer: React.FunctionComponent<Props> = ({
    isFullHeight,
    path = '',
}) => 
{
    const [ loaded, setLoaded ] = useState<boolean>(false);
    const [ isLabPage ] = useState<boolean>(path === 'lab');
    const [ isTitleHovered, setIsTitleHovered ] = useState<boolean>(false);

    const handleSocialLinkClick = (url: string) => 
    {
        window.open(url, '_blank');
    };

    useEffect(() => 
    {
        setLoaded(true);
    }, []);

    return (
        <ContainerWrapper isLabPage={isLabPage}>
            <Container id="contact-us" loaded={loaded} isFullHeight={isFullHeight}>
                <TitleContainer
                    onMouseEnter={() => 
                    {
                        setIsTitleHovered(true);
                    }}
                    onMouseLeave={() => 
                    {
                        setIsTitleHovered(false);
                    }}
                    onClick={() =>
                        handleSocialLinkClick('https://calendly.com/mathewmozaffari')
                    }
                >
                    <TitleWrapper>
                        <Title isLabPage={isLabPage} isHover={isTitleHovered}>
                            {'Let\'s Get Started'}
                        </Title>

                        <Title isLabPage={isLabPage} isHover={isTitleHovered}>
                            {'Let\'s Get Started'}
                        </Title>
                    </TitleWrapper>

                    <ArrowWrapper>
                        <ArrowBack
                            src={IMAGES.arrowIconWhite}
                            alt="Right Arrow"
                            isHover={isTitleHovered}
                        />
                        <Arrow
                            src={IMAGES.arrowIconWhite}
                            alt="Right Arrow"
                            isHover={isTitleHovered}
                        />
                    </ArrowWrapper>
                </TitleContainer>

                <InnerContainer>
                    <ContentContainer>
                        <AddressesAndContacts>
                            <Addresses>
                                <AddressText>379 Shuter Street, Toronto, ON</AddressText>
                                <AddressText>M5A 1X3</AddressText>
                            </Addresses>

                            <Contacts>
                                <ContactLink isLabPage={isLabPage} href="tel:+1 416 453 3812">
                  +1 (416) 453-3812
                                </ContactLink>
                                <ContactLink
                                    isLabPage={isLabPage}
                                    href="mailto:create@speer.io"
                                >
                  create@speer.io
                                </ContactLink>
                            </Contacts>

                            <Socials>
                                {socialMedias.map((media) => (
                                    <SocialLink
                                        key={`footer-link-${media.name}`}
                                        isLabPage={isLabPage}
                                        src={IMAGES[media.iconKey]}
                                        onClick={() => 
                                        {
                                            handleSocialLinkClick(media.src);
                                        }}
                                    />
                                ))}
                            </Socials>
                        </AddressesAndContacts>

                        <FooterLinks>
                            <div>
                                <FooterTitle>Menu</FooterTitle>
                                <FooterLinkContainer>
                                    <FooterLink href="/work">Services</FooterLink>
                                    <FooterLink href="/press">Press</FooterLink>
                                    <FooterLink href="/team">Team</FooterLink>
                                    <FooterLink href="/blog">Blog</FooterLink>
                                </FooterLinkContainer>
                            </div>

                            <div>
                                <FooterTitle>Our Products</FooterTitle>
                                <FooterLinkContainer>
                                    <FooterLink href="/lab">Labs</FooterLink>
                                    <FooterLink href="https://getonpath.io/" target="_blank">
                    Onpath
                                    </FooterLink>
                                    <FooterLink href="/password-generator">
                    Password Generator
                                    </FooterLink>
                                    <FooterLink href="/color-contrast-checker">
                    Color Contrast Checker
                                    </FooterLink>
                                </FooterLinkContainer>
                            </div>
                        </FooterLinks>
                    </ContentContainer>

                    <RightContainer>
                        <Copyright isLabPage={isLabPage}>
              Copyright ©︎ 2024 Speer Technologies Inc. all rights reserved
                        </Copyright>
                    </RightContainer>
                </InnerContainer>
            </Container>
        </ContainerWrapper>
    );
};

const ContainerWrapper = styled.div<{ isLabPage: boolean }>`
  background-color: black;
`;

const Container = styled.footer<{ isFullHeight?: boolean; loaded: boolean }>`
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
  transition: opacity 0.5s ease-in;
  overflow: hidden;

  @media ${MediaQuery.tablet} {
    ${({ isFullHeight }) =>
        isFullHeight &&
      `
            height: 100%;
        `}
  }
`;

const TitleContainer = styled.div`
  flex-shrink: 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  @media ${MediaQuery.mobileS} {
    flex-direction: column;
    margin: 2rem 1rem;
    gap: 2rem;
  }

  @media ${MediaQuery.tablet} {
    flex-direction: row;
    margin: 6rem 4rem;
    justify-content: space-between;
    gap: 0;
  }

  @media ${MediaQuery.laptopS} {
    &:hover .scroll-content {
      animation: scroll-up 2s linear forwards;
    }
  }
`;

const TitleWrapper = styled.div`
  cursor: pointer;

  & > *:last-child {
    @media ${MediaQuery.mobileS} {
      display: none;
    }
    @media ${MediaQuery.laptopS} {
      display: block;
    }
  }

  @media ${MediaQuery.laptopS} {
    height: 5rem;
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
  }
`;

const Title = styled.p<{ isLabPage: boolean; isHover: boolean }>`
  position: relative;
  font-family: "Poppins-Regular";
  /* font-size: calc(1.65625em + 2vw); */
  line-height: 1.1em;
  white-space: pre-line;
  color: white;
  cursor: pointer;

  ${({ isLabPage }) =>
        isLabPage
            ? 'background-image: linear-gradient(95deg, #c0a8f0 2%, #ff8593 50%); -webkit-background-clip: text; background-clip: text; -webkit-text-fill-color: transparent;'
            : ''}

  @media ${MediaQuery.mobileS} {
    font-size: 56px;
  }

  @media ${MediaQuery.tablet} {
    font-size: 44px;
  }

  @media ${MediaQuery.laptopS} {
    font-size: 72px;
    height: 5rem;

    transform: ${({ isHover }) =>
        isHover
            ? 'translate3d(0px, -102% , 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
            : 'translate3d(0px, 0 , 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'};
    transform-style: preserve-3d;
    transition: all 0.5s ease-in-out;
  }
`;

const InnerContainer = styled.div`
  border-top: 1px solid white;

  @media ${MediaQuery.mobileS} {
    padding: 2rem;
  }

  @media ${MediaQuery.tablet} {
    padding: 4rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;

  @media ${MediaQuery.mobileS} {
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
  }

  @media ${MediaQuery.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Addresses = styled.div`
  display: flex;
  color: white;

  @media ${MediaQuery.tablet} {
    flex-direction: column;
  }
`;

const AddressText = styled.p`
  font-size: calc(0.28125em + 2vw);
  color: white;

  @media ${MediaQuery.mobileS} {
    font-family: "Poppins-Regular";
  }

  @media ${MediaQuery.tablet} {
    font-family: "Poppins-Light";
    font-size: 1.0625em;
  }
`;

const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

const ContactLink = styled.a<{ isLabPage: boolean }>`
  color: ${({ isLabPage }) => (isLabPage ? Palette.BLACK : Palette.WHITE)};
  color: white;
  font-size: calc(6.375px + 1.5vw);
  text-decoration: none;

  @media ${MediaQuery.tablet} {
    font-family: "Poppins-Regular";
  }

  @media ${MediaQuery.tablet} {
    background-color: transparent;
    color: white;

    font-family: "Poppins-Light";
    font-size: 1.0625em;
  }
`;

const Socials = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
`;

const SocialLink = styled.li<{ isLabPage: boolean; src: string }>`
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url(${({ src }) => src});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  @media ${MediaQuery.tablet} {
    width: 24px;
    height: 24px;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${MediaQuery.mobileS} {
    flex-direction: column;
    gap: 2rem;
  }
  @media ${MediaQuery.tablet} {
    flex-direction: row;
    gap: 6rem;
  }
`;

const FooterTitle = styled.p`
  font-family: "Poppins-Bold";
  font-size: calc(0.28125em + 2vw + 1);
  color: white;

  @media ${MediaQuery.tablet} {
    margin-bottom: 0.5rem;
  }
`;

const FooterLinkContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  @media ${MediaQuery.mobileS} {
    flex-direction: row;
    gap: 4px 16px;
    flex-wrap: wrap;
  }
  @media ${MediaQuery.tablet} {
    flex-direction: column;
    gap: 0.1rem;
  }
`;

const FooterLink = styled.a`
  font-family: "Poppins-Light";
  font-size: calc(0.28125em + 2vw);
  text-decoration: none;
  color: white;
  margin: 0.3rem 0;

  @media ${MediaQuery.mobileS} {
    width: max-content;
  }

  @media ${MediaQuery.tablet} {
    font-size: 1.0625em;
    width: auto;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const RightContainer = styled.div`
  display: flex;
  margin-top: 3rem;

  @media ${MediaQuery.mobileS} {
    flex-direction: column;
    justify-content: flex-start;
  }

  @media ${MediaQuery.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Copyright = styled.div<{ isLabPage: boolean }>`
  width: fit-content;

  font-family: "Poppins-Light";
  font-size: calc(0.28125em + 1.5vw);
  color: white;

  @media ${MediaQuery.tablet} {
    font-size: 1em;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;

  @media ${MediaQuery.mobileS} {
    width: 100%;
  }
  @media ${MediaQuery.tablet} {
    width: 40%;
  }

  @media ${MediaQuery.laptopS} {
    position: relative;
    width: 20%;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
`;

const Arrow = styled.img<{ isHover: boolean }>`
  @media ${MediaQuery.mobileS} {
    width: 90%;
  }

  @media ${MediaQuery.laptopS} {
    position: absolute;
    width: 100%;
    top: auto;
    left: 100%;
    transform: ${({ isHover }) =>
        isHover
            ? 'translate3d(0px, 0, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
            : 'translate3d(-100%, 0, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'};
    transform-style: preserve-3d;
    transition: all 0.5s ease-in-out;
  }
`;

const ArrowBack = styled.img<{ isHover: boolean }>`
  @media ${MediaQuery.mobileS} {
    display: none;
  }

  @media ${MediaQuery.laptopS} {
    display: block;
    position: absolute;
    width: 100%;
    top: auto;
    left: -10%;
    transform: ${({ isHover }) =>
        isHover
            ? 'translate3d(0px, 0, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
            : 'translate3d(-120%, 0, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'};
    transform-style: preserve-3d;
    transition: all 0.5s ease-in-out;
  }
`;

const AddressesAndContacts = styled.div`
  display: flex;
  flex-direction: column;

  @media ${MediaQuery.mobileS} {
    gap: 1rem;
  }

  @media ${MediaQuery.tablet} {
    gap: 1rem;
    font-size: calc(4.51px + 1vw);
  }

  @media (min-width: 1150px) {
    font-size: 1em;
  }
`;

export default Footer;
