import IMAGES from 'assets/images';

export const NODE_ENV = process.env.REACT_APP_NODE_ENV;

/* eslint-disable */
export const noop = () => {};

export const MOBILE = {
    window: {
        maxWidth: 766
    }
};

export const TABLET = {
    window: {
        maxWidth: 1023
    }
}

export enum CURSOR_STYLES
{
    GRAB = 'grab',
    DEFAULT = 'default'
}

export const CAROUSEL_3D_CARDS_DATA = {
    IMAGES: [
        IMAGES.labCarousel1,
        IMAGES.labCarousel2,
        IMAGES.labCarousel3,
        IMAGES.labCarousel4,
        IMAGES.labCarousel5,
        IMAGES.labCarousel6,
        IMAGES.labCarousel7,
        IMAGES.labCarousel8,
        IMAGES.labCarousel9,
        IMAGES.labCarousel10,
        IMAGES.labCarousel11
    ],
    CARD_WIDTH: 25,
    CARD_HEIGHT: 25,
    RESPONSIVENESS: 3 
};

export const SPHERE_COLOURS = {
    HOME_PAGE: [
        'rgba(255, 0, 102, 0.006)',
        'rgba(255, 0, 72, 0.012)',
        'rgba(255, 0, 43, 0.018)',
        'rgba(255, 0, 13, 0.024)',
        'rgba(255, 17, 0, 0.03)',
        'rgba(255, 47, 0, 0.036)',
        'rgba(255, 77, 0, 0.042)',
        'rgba(255, 106, 0, 0.048)',
        'rgba(255, 136, 0, 0.054)',
        'rgba(255, 166, 0, 0.06)',
        'rgba(255, 196, 0, 0.066)',
        'rgba(255, 225, 0, 0.072)',
        'rgba(255, 255, 0, 0.078)',
        'rgba(225, 255, 0, 0.084)',
        'rgba(196, 255, 0, 0.09)',
        'rgba(166, 255, 0, 0.096)',
        'rgba(136, 255, 0, 0.102)',
        'rgba(106, 255, 0, 0.108)',
        'rgba(77, 255, 0, 0.114)',
        'rgba(47, 255, 0, 0.12)',
        'rgba(17, 255, 0, 0.126)',
        'rgba(0, 255, 13, 0.132)',
        'rgba(0, 255, 43, 0.138)',
        'rgba(0, 255, 72, 0.144)',
        'rgba(0, 255, 102, 0.15)',
        'rgba(0, 255, 132, 0.156)',
        'rgba(0, 255, 162, 0.162)',
        'rgba(0, 255, 191, 0.168)',
        'rgba(0, 255, 221, 0.174)',
        'rgba(0, 255, 251, 0.18)',
        'rgba(0, 230, 255, 0.186)',
        'rgba(0, 200, 255, 0.192)',
        'rgba(0, 170, 255, 0.198)',
        'rgba(0, 140, 255, 0.204)',
        'rgba(0, 111, 255, 0.21)',
        'rgba(0, 81, 255, 0.216)',
        'rgba(0, 51, 255, 0.222)',
        'rgba(0, 21, 255, 0.228)',
        'rgba(9, 0, 255, 0.234)',
        'rgba(38, 0, 255, 0.24)',
        'rgba(68, 0, 255, 0.246)',
        'rgba(98, 0, 255, 0.252)',
        'rgba(128, 0, 255, 0.258)',
        'rgba(157, 0, 255, 0.264)',
        'rgba(187, 0, 255, 0.27)'
    ],
    LAB_PAGE: [
        'rgba(219, 0, 171, 1)',
        'rgba(255, 67, 196, 1)',
        'rgba(146, 0, 162, 1)',
        'rgba(195, 0, 161, 1)'
    ]
};