import React, { useState } from 'react';
import styled from 'styled-components';
import { MediaQuery } from 'core/config';

interface ServiceCardProps {
    number: string;
    title: string;
    items: string[];
}

const ServiceCard: React.FC<ServiceCardProps> = ({ number, title, items }) => 
{
    const [ isHovered, setIsHovered ] = useState(false);

    return (
        <CardContainer
            isHovered={isHovered}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className='service-card'
        >
            <Entry isHovered={isHovered}>{number}</Entry>
            <Title isHovered={isHovered}>{title}</Title>
            <ItemsContainer>
                {items.map((item, index) => (
                    <Item key={`${number}-${title}-tech-${index}`} isHovered={isHovered}>{item}</Item>
                ))}
            </ItemsContainer>
        </CardContainer>
    );
};

export default ServiceCard;

const CardContainer = styled.div<{ isHovered: boolean }>`
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    
    border-radius: 15px;
    background-color: ${({ isHovered }) => (isHovered ? 'black' : '#F8F8F8')};
    color: ${({ isHovered }) => (isHovered ? 'white' : 'black')};
    box-shadow: ${({ isHovered }) => (isHovered ? '0px 4px 8px rgba(0, 0, 0, 0.1)' : '0px 2px 4px rgba(0, 0, 0, 0.1)')};
    transition: color 0.3s ease-in-out;

    @media ${MediaQuery.mobileS} {
        padding: 1rem;
        width: 280px;
        height: 315px;
    }

    @media ${MediaQuery.laptopS} {
        padding: 2rem;
        width: 320px;
        height: 360px;
    }

    @media ${MediaQuery.laptopL} {
        padding: 2rem;
        width: 400px;
        height: 450px;
    }
`;

const Entry = styled.h3<{ isHovered: boolean }>`
    font-size: var(--Heading-H3, 48px);
    font-style: normal;
    font-weight: 500;
    line-height: 124%;
    color: ${({ isHovered }) => (isHovered ? 'white' : 'black')};

    @media ${MediaQuery.mobileS} {
        font-size: var(--Heading-H3, 24px);
    }
    @media ${MediaQuery.tablet} {
        font-size: var(--Heading-H3, 32px);
    }
        @media ${MediaQuery.laptopS} {
        font-size: var(--Heading-H3, 48px);
    }
`;

const Title = styled.h4<{ isHovered: boolean }>`
    font-size: var(--Heading-H4, 32px);
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    color: ${({ isHovered }) => (isHovered ? 'white' : 'black')};
    white-space: pre-wrap;

    @media ${MediaQuery.mobileS} {
        font-size: var(--Heading-H4, 22px);
    }

    @media ${MediaQuery.tablet} {
        font-size: var(--Heading-H4, 24px);
    }

    @media ${MediaQuery.laptopS} {
        font-size: var(--Heading-H4, 32px);
    }
`;

const ItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    flex-shrink: 0;
    align-self: stretch;
`;

const Item = styled.div<{ isHovered: boolean }>`
    padding: 5px 10px;
    border: 1px solid ${({ isHovered }) => (isHovered ? '#2A2A2A' : '#92908A')};
    border-radius: 20px;
    background-color: ${({ isHovered }) => (isHovered ? '#2A2A2A' : '#F8F8F8')};

    @media ${MediaQuery.mobileS} {
        font-size: 10px;
    }

    @media ${MediaQuery.tablet} {
        font-size: 12px;
    }
`;
