import React from 'react';
import styled from 'styled-components';

interface Props {
    src?          : string;
    children      : React.ReactNode;
    cssOverride?  : string;
    hasMobileSrc? : boolean;
}

export const getMobileSrc = (src?: string) : string =>
{
    if (src) 
    {
        const srcWithoutExtension = src.substring(0, src.length - 4);
        const extension = src.substring(src.length - 3);
        return `${srcWithoutExtension}-mobile.${extension}`;
    }
    else
    {
        return '';
    }
};

export const ImageWrapper : React.FunctionComponent<Props> = ({ src, children, cssOverride, hasMobileSrc }) =>
{
    return (
        <Picture css={cssOverride}>
            {hasMobileSrc && <>
                <source media='(max-width: 766px)' srcSet={`${getMobileSrc(src)}.webp`} type='image/webp' />
                <source media='(max-width: 766px)' srcSet={`${src}`} type='image/png' />
            </>} 
            <source srcSet={`${src}.webp`} type='image/webp' />
            <source srcSet={src} type='image/png' />
            {children}
        </Picture>
    );
};

const Picture = styled.picture<{ css?: string }>`
    ${({ css }) => css ?? ''}
`;