import { Data } from 'core/utilities/interfaces';

const IMAGES = {
    /* Splash */
    defientLogo: require('./defient/defient_logo.png').default,
    defientVisualLaptop: require('./defient/defient_visual-laptop.png').default,
    defientVisualBlimp: require('./defient/defient_visual-blimp.png').default,
    defientVisualWorld: require('./defient/defient_visual-world.png').default,

    dealerMethodsLogo: require('./dealer-methods/dealer_methods_logo.png')
        .default,
    mapBackground: require('./dealer-methods/map_background.png').default,
    pickCarPhone: require('./dealer-methods/iPhone_pick_car.png').default,
    welcomeBackPhone: require('./dealer-methods/iPhone_welcome_back.png').default,
    reservationsPhone: require('./dealer-methods/iPhone_reservations.png')
        .default,
    distancePhone: require('./dealer-methods/iPhone_distance.svg').default,
    schedulePhone: require('./dealer-methods/iPhone_schedule.svg').default,
    pickupPhone: require('./dealer-methods/iPhone_pickup.svg').default,

    manzilLogo: require('./manzil/manzil_logo.png').default,
    manzilLaptop: require('./manzil/manzil_laptop.png').default,
    manzilCoin: require('./manzil/manzil_coin.svg').default,

    betakit: require('./home-showcase/betakit.png').default,
    cbc: require('./home-showcase/cbc.png').default,
    complex: require('./home-showcase/complex.png').default,
    ef: require('./home-showcase/ef.png').default,
    g20yea: require('./home-showcase/g20_yea.png').default,
    futurpreneur: require('./home-showcase/futurpreneur.png').default,
    techcrunch: require('./home-showcase/techcrunch.png').default,
    theGlobeIcon: require('./home-showcase/the_globe_and_mail_logo.png').default,
    chillpillPreview: require('./home-showcase/chillpill_preview.png').default,
    developer30Preview: require('./home-showcase/developer30_preview.png').default,
    elizabethMayPreview: require('./home-showcase/elizabeth_may_preview.png').default,
    xplorationCapitalPreview: require('./home-showcase/xploration_capital_preview.png').default,
    g20YeaFuturpreneurPreview: require('./home-showcase/g20_yea_futurpreneur_preview.jpg').default,
    theGlobePreview: require('./home-showcase/the_globe_preview.png').default,
    //Press

    theGlobeAndMail: require('./article/globe-and-mail.png').default,

    featured1: require('./featured/featured1.png').default,
    featured2: require('./featured/featured2.png').default,
    featured3: require('./featured/featured3.png').default,
    featured4: require('./featured/featured4.png').default,
    featured5: require('./featured/featured5.png').default,
    featured6: require('./featured/featured6.png').default,
    featured7: require('./featured/featured7.png').default,

    success1: require('./successImages/success1.png').default,
    success2: require('./successImages/success2.png').default,
    success3: require('./successImages/success3.png').default,
    success4: require('./successImages/success4.png').default,
    success5: require('./successImages/success5.png').default,
    success6: require('./successImages/success6.png').default,
    
    arrowUpRight: require('./icons/arrow-up-right.svg').default,
    arrowUpRightWhite: require('./icons/arrow-up-right-white.svg').default,

    /* Blog */
    carouselCaret: require('./blog/arrow-ios-forward-outline.svg').default,
    carouselImage: require('./blog/carousel-image.png').default,
    carouselImage2: require('./blog/carousel-image2.png').default,
    carouselImage3: require('./blog/carousel-image3.png').default,
    carouselImage4: require('./blog/carousel-image4.png').default,

    postTile: require('./blog/post-tile.png').default,
    postTile2: require('./blog/post-tile2.png').default,
    postTile3: require('./blog/post-tile3.png').default,
    postTile4: require('./blog/post-tile4.png').default,

    authorPhoto: require('./blog/author-photo.png').default,

    graph                          : require('./blog/graph.png').default,
    postContent                    : require('./blog/posts/podd_promo.png').default,
    postContentMap                 : require('./blog/posts/podd_map.png').default,
    graph1                         : require('./blog/posts/graph1-futureoftech.png').default,
    graph2                         : require('./blog/posts/graph2-futureoftech.png').default,

    /* Case Study Pages */
    manzilHero: require('./manzil/manzil_hero.png').default,
    manzilCaseStudyImage0: require('./manzil/manzil_image0.png').default,
    manzilCaseStudyImage1: require('./manzil/manzil_image1.png').default,
    manzilCaseStudyImage2: require('./manzil/manzil_image2.png').default,
    manzilCaseStudyImage3: require('./manzil/manzil_image3.png').default,
    manzilCaseStudyImage4: require('./manzil/manzil_image4.png').default,
    manzilCaseStudyCarousel0: require('./manzil/manzil_carousel0.png').default,
    manzilCaseStudyCarousel1: require('./manzil/manzil_carousel1.png').default,
    manzilCaseStudyCarousel2: require('./manzil/manzil_carousel2.png').default,
    manzilCaseStudyCarousel3: require('./manzil/manzil_carousel3.png').default,
    manzilCaseStudyCarousel4: require('./manzil/manzil_carousel4.png').default,
    manzilCaseStudyBackground: require('./manzil/manzil_background.png').default,
    manzilTitle: require('./manzil/manzil_title.png').default,
    manzilShowcase1: require('./manzil/manzil_showcase_1.webp').default,

    defientHero: require('./defient/defient_hero.png').default,
    defientCaseStudyImage0: require('./defient/defient_image0.png').default,
    defientCaseStudyImage1: require('./defient/defient_image1.png').default,
    defientCaseStudyImage2: require('./defient/defient_image2.png').default,
    defientCaseStudyImage3: require('./defient/defient_image3.png').default,
    defientCaseStudyImage4: require('./defient/defient_image4.png').default,
    defientCaseStudyImage5: require('./defient/defient_image5.png').default,
    defientCaseStudyBackground: require('./defient/defient_background.png').default,
    defientLilBLogo: require('./defient/defient_lil-b.svg').default,
    defientAppStoreImage: require('./defient/defient_app-store.svg').default,
    defientTitle: require('./defient/defient_title.png').default,
    defientShowcase1: require('./defient/defient_case_study_hero.png').default,
    defientMobileTitle: require('./defient/defient_title2.png').default,

    dropverseTitle: require('./dropverse/dropverse_title.png').default,
    dropverseShowcase1: require('./dropverse/dropverse_showcase_1.webp').default,

    poppyTitle: require('./poppy/poppy_title.png').default,
    poppyShowcase1: require('./poppy/poppy_showcase_1.webp').default,

    chillrxTitle: require('./cprx/chillrx_title.png').default,
    chillrxShowcase1: require('./cprx/chillrx_showcase_1.webp').default,

    onpathTitle: require('./onpath/onpath_title.png').default,
    onpathShowcase1: require('./onpath/onpath_showcase_1.webp').default,

    onpathPaletteLogo: require('./onpath/onpath_palette_logo.png').default,
    chillRxLogo: require('./cprx/chillrx_red_logo.png').default,
    dropverseLogo: require('./dropverse/dropverse_logo.webp').default,
    poppyLogo: require('./poppy/poppy_logo.png').default,

    dropversePalette1: require('./dropverse/dropverse_palette_1.png').default,
    dropversePalette2: require('./dropverse/dropverse_palette_2.png').default,
    dropversePalette3: require('./dropverse/dropverse_palette_3.png').default,
    dropversePalette4: require('./dropverse/dropverse_palette_4.png').default,
    dropversePalette5: require('./dropverse/dropverse_palette_5.png').default,

    onpathResults: require('./onpath/onpath_results.webp').default,
    chillRxResults: require('./cprx/chillrx_results.png').default,
    poppyResults: require('./poppy/poppy_results.webp').default,
    dropverseResults: require('./dropverse/dropverse_results.png').default,
    manzilResults: require('./manzil/manzil_results.webp').default,
    defientResults: require('./defient/defient_results_1.png').default,

    chillrxStack1: require('./stack/chillrx-stack1.webp').default,
    chillrxStack2: require('./stack/chillrx-stack2.webp').default,
    chillrxStack3: require('./stack/chillrx-stack3.webp').default,
    chillrxStack4: require('./stack/chillrx-stack4.webp').default,

    defientStack1: require('./stack/defient-stack1.webp').default,
    defientStack2: require('./stack/defient-stack2.webp').default,
    defientStack3: require('./stack/defient-stack3.webp').default,
    defientStack4: require('./stack/defient-stack4.webp').default,

    dropverseStack1: require('./stack/dropverse-stack1.webp').default,
    dropverseStack2: require('./stack/dropverse-stack2.webp').default,
    dropverseStack3: require('./stack/dropverse-stack3.webp').default,
    dropverseStack4: require('./stack/dropverse-stack4.webp').default,

    manzilStack1: require('./stack/manzil-stack1.webp').default,
    manzilStack2: require('./stack/manzil-stack2.webp').default,
    manzilStack3: require('./stack/manzil-stack3.webp').default,
    manzilStack4: require('./stack/manzil-stack4.webp').default,

    onpathStack1: require('./stack/onpath-stack1.webp').default,
    onpathStack2: require('./stack/onpath-stack2.webp').default,
    onpathStack3: require('./stack/onpath-stack3.webp').default,
    onpathStack4: require('./stack/onpath-stack4.webp').default,

    poppyStack1: require('./stack/poppy-stack1.webp').default,
    poppyStack2: require('./stack/poppy-stack2.webp').default,
    poppyStack3: require('./stack/poppy-stack3.webp').default,
    poppyStack4: require('./stack/poppy-stack4.webp').default,
    
    chillRxDevelopment: require('./cprx/chillrx_development.png').default,

    /* Labs */
    /* Carousel 3D Cards Labs Page */
    labCarousel1: require('./lab/lab_carousel_1.png').default,
    labCarousel2: require( './lab/lab_carousel_2.png' ).default,
    labCarousel3: require( './lab/lab_carousel_3.png' ).default,
    labCarousel4: require( './lab/lab_carousel_4.png' ).default,
    labCarousel5: require( './lab/lab_carousel_5.png' ).default,
    labCarousel6: require( './lab/lab_carousel_6.png' ).default,
    labCarousel7: require( './lab/lab_carousel_7.png' ).default,
    labCarousel8: require( './lab/lab_carousel_8.png' ).default,
    labCarousel9: require( './lab/lab_carousel_9.png' ).default,
    labCarousel10: require( './lab/lab_carousel_10.png' ).default,
    labCarousel11: require( './lab/lab_carousel_11.png' ).default,
    labCarousel12: require( './lab/lab_carousel_12.png' ).default,

    web3Sphere: require( './lab/web3_sphere.svg' ).default,
    digitalExperience: require( './lab/digital_experience.svg' ).default,
    vcConnection: require( './lab/vc_connect.svg' ).default,
    web2Dev: require( './lab/web2_dev.svg' ).default,

    purpleRectangleEdgeCut: require( './lab/rectangle_purple_cut_edge.svg' ).default,
    purpleRectangleEdgeCutMobile: require( './lab/rectangle_purple_cut_edge_mobile.svg' ).default,
    purpleRectangle: require( './lab/rectangle_purple.svg' ).default,
    purpleCircle: require( './lab/circle_purple.svg' ).default,
    purpleLineVertical: require( './lab/line_vertical_purple.svg' ).default,

    internSphere: require( './lab/intern_sphere.svg' ).default,


    /* Work */
    manzilTile: require('./manzil/manzil_tile.png').default,
    defientTile: require('./defient/defient_tile.png').default,
    auggyTile: require('./auggy/auggy_tile.png').default,
    dropverseTile: require('./dropverse/dropverse_tile.png').default,
    cprxTile: require('./cprx/cprx_tile.png').default,
    dealerMethodsTile: require('./dealer-methods/dealer-methods_tile.png')
        .default,

    /* Team */
    teamHero                       : require('./team/team_hero.png').default,
    teamCarouselImage0             : require('./team/team_carousel0.png').default,
    teamCarouselImage1             : require('./team/team_carousel1.png').default,
    teamCarouselImage2             : require('./team/team_carousel2.png').default,
    teamCarouselImage3             : require('./team/team_carousel3.png').default,
    teamValueWords                 : require('./team/team_value_words.svg').default,
    teamAboutUsGroup               : require('./team/team_about_us.svg').default,
    teamValues                     : require('./team/team_values.svg').default,
    teamSpeer                      : require('./team/team_speer.svg').default,

    /* Misc */
    speerLogo                      : require( './speer/speer_logo_header.svg' ).default,
    speerLogoWhite                 : require( './speer/speer_logo_header_white.svg' ).default,
    speerLogoFooter                : require('./speer/speer_logo_footer.svg').default,
    speerLogoMobile                : require( './speer/speer_logo_mobile.svg' ).default,
    speerLogoFooterPurple          : require( './speer/speer_logo_footer_purple.svg' ).default,
    speerLogoFooterWhite           : require( './speer/speer_logo_footer_white.svg' ).default,

    arrowIcon                      : require( './icons/long_left_arrow.svg' ).default,
    arrowIconWhite                 : require( './icons/long_left_arrow_white.svg' ).default,
    rightArrowIcon                 : require( './icons/right-arrow.png' ).default,
    midRightArrowIcon              : require( './icons/mid_rigth_arrow.svg' ).default,
    copyIcon                       : require( './icons/copy.svg' ).default,
    closeIcon                      : require( './icons/close.svg' ).default,
    closeBlackIcon                 : require( './icons/close-black.svg' ).default, 
    refreshIcon                    : require( './icons/refresh.svg' ).default,
    starFilledIcon                 : require( './icons/star-filled.svg' ).default,
    starUnfilledIcon               : require( './icons/star-unfilled.svg' ).default,
    downArrowCircleIcon            : require('./team/arrow-down-circle-fill.svg').default,
    downArrowCircleIconBlack       : require('./team/arrow-down-circle-fill-black.svg').default,
    checkInCircleIcon              : require( './icons/check-in-circle.svg' ).default,
    crossInCircleIcon              : require( './icons/cross-in-circle.svg' ).default,
    buttonArrowIcon                : require('./icons/ios-arrow-thin-right.svg').default,
    swapIcon                       : require( './icons/swap.svg' ).default,
    valueIntegrityIcon             : require('./team/value-integrity.svg').default,
    valueInnovateIcon              : require('./team/value-innovate.svg').default,
    valueImpactIcon                : require('./team/value-impact.svg').default,

    linkedInIcon                   : require('./icons/linkedin.svg').default,
    instagramIcon                  : require('./icons/instagram.svg').default,
    githubIcon                     : require('./icons/github.svg').default,
    websiteIcon                    : require('./icons/website.svg').default,
    linkedInIconWhite              : require('./icons/linkedin-white.svg').default,
    instagramIconWhite             : require('./icons/instagram-white.svg').default,
    facebookIconWhite              : require('./icons/facebook-white.svg').default,
    tiktokIconWhite                : require('./icons/tiktok-white.svg').default,

    lockIcon                       : require('./icons/lock.png').default,
    starIcon                       : require('./icons/star.png').default,

    appStoreButton                 : require('./icons/app-store-black.png').default,
    playStoreButton                : require('./icons/play-store.png').default,
    appStoreWhiteButton                 : require('./icons/app-store-white.svg').default,
    playStoreWhiteButton                : require('./icons/play-store-white.svg').default,

    appStoreBlackButton: require('./icons/app-store-black-bg.png').default,
    googlePlayButton: require('./icons/google-play-color.png').default,


    appStoreCurved: require('./icons/app-store-curved.png').default,
    playStoreCurved: require('./icons/play-store-curved.png').default,

    arrowRightButton: require('./icons/arrow-right.svg').default,
    arrowRightV2Icon: require('./icons/arrow-right-v2.svg').default,

    ctgcWinner: require('./globe-and-mail/ctgc-winner.png').default,

    /* Services */
    serviceDevBlue: require('./services/dev_blue.png').default,
    serviceDesignBlue: require('./services/design_blue.png').default,
    serviceBrandingBlue: require('./services/branding_blue.png').default,
    serviceModelingBlue: require('./services/modeling_blue.png').default,
    serviceDevYellow: require('./services/dev_yellow.png').default,
    serviceDesignYellow: require('./services/design_yellow.png').default,
    serviceBrandingYellow: require('./services/branding_yellow.png').default,
    serviceSocialYellow: require('./services/social_yellow.png').default,
    serviceDevGreen: require('./services/dev_green.png').default,
    serviceDesignGreen: require('./services/design_green.png').default,
    serviceBrandingGreen: require('./services/branding_green.png').default,
    serviceDevOrange: require('./services/dev_orange.png').default,
    serviceDesignOrange: require('./services/design_orange.png').default,
    serviceBrandingOrange: require('./services/branding_orange.png').default,
    serviceDevRed: require('./services/dev_red.png').default,
    serviceDesignRed: require('./services/design_red.png').default,
    serviceBrandingRed: require('./services/branding_red.png').default,

    /* Solutions */
    solutionBlue1: require('./solutions/blue_1.png').default,
    solutionBlue2: require('./solutions/blue_2.png').default,
    solutionBlue3: require('./solutions/blue_3.png').default,
    solutionYellow1: require('./solutions/yellow_1.png').default,
    solutionYellow2: require('./solutions/yellow_2.png').default,
    solutionYellow3: require('./solutions/yellow_3.png').default,
    solutionOrange1: require('./solutions/orange_1.png').default,
    solutionOrange2: require('./solutions/orange_2.png').default,
    solutionOrange3: require('./solutions/orange_3.png').default,
} as Data;

const imagesWithAdditionalFormats = [
    /* Splash */
    { name: 'dealer_methods_logo', path: 'dealer-methods', formats: [ 'webp' ] },
    { name: 'defient_logo', path: 'defient', formats: [ 'webp' ] },
    {
        name: 'defient_visual-laptop',
        path: 'defient',
        formats: [ 'webp', 'mobile' ],
    },
    {
        name: 'defient_visual-blimp',
        path: 'defient',
        formats: [ 'webp', 'mobile' ],
    },
    {
        name: 'defient_visual-world',
        path: 'defient',
        formats: [ 'webp', 'mobile' ],
    },
    {
        name: 'iPhone_pick_car',
        path: 'dealer-methods',
        formats: [ 'webp', 'mobile' ],
    },
    {
        name: 'iPhone_welcome_back',
        path: 'dealer-methods',
        formats: [ 'webp', 'mobile' ],
    },
    {
        name: 'iPhone_reservations',
        path: 'dealer-methods',
        formats: [ 'webp', 'mobile' ],
    },
    { name: 'manzil_logo', path: 'manzil', formats: [ 'webp' ] },
    { name: 'manzil_laptop', path: 'manzil', formats: [ 'webp' ] },
    {
        name: 'map_background',
        path: 'dealer-methods',
        formats: [ 'webp', 'mobile' ],
    },

    /* Blog */
    { name: 'author-photo', path: 'blog', formats: [ 'webp' ] },
    { name: 'carousel-image', path: 'blog', formats: [ 'webp' ] },
    { name: 'carousel-image2', path: 'blog', formats: [ 'webp' ] },
    { name: 'carousel-image3', path: 'blog', formats: [ 'webp' ] },
    { name: 'carousel-image4', path: 'blog', formats: [ 'webp', 'mobile' ] },
    { name: 'graph', path: 'blog', formats: [ 'webp', 'mobile' ] },
    { name: 'post-tile', path: 'blog', formats: [ 'webp', 'mobile' ] },
    { name: 'post-tile2', path: 'blog', formats: [ 'webp', 'mobile' ] },
    { name: 'post-tile3', path: 'blog', formats: [ 'webp', 'mobile' ] },
    { name: 'post-tile4', path: 'blog', formats: [ 'webp', 'mobile' ] },

    /* Case Study Pages */
    { name: 'manzil_background', path: 'manzil', formats: [ 'webp', 'mobile' ] },
    { name: 'manzil_carousel0', path: 'manzil', formats: [ 'webp', 'mobile' ] },
    { name: 'manzil_carousel1', path: 'manzil', formats: [ 'webp', 'mobile' ] },
    { name: 'manzil_carousel2', path: 'manzil', formats: [ 'webp', 'mobile' ] },
    { name: 'manzil_carousel3', path: 'manzil', formats: [ 'webp', 'mobile' ] },
    { name: 'manzil_carousel4', path: 'manzil', formats: [ 'webp', 'mobile' ] },
    { name: 'manzil_hero', path: 'manzil', formats: [ 'webp', 'mobile' ] },
    { name: 'manzil_image0', path: 'manzil', formats: [ 'webp', 'mobile' ] },
    { name: 'manzil_image1', path: 'manzil', formats: [ 'webp', 'mobile' ] },
    { name: 'manzil_image2', path: 'manzil', formats: [ 'webp', 'mobile' ] },
    { name: 'manzil_image3', path: 'manzil', formats: [ 'webp', 'mobile' ] },
    { name: 'manzil_image4', path: 'manzil', formats: [ 'webp', 'mobile' ] },
    { name: 'manzil_case_study_hero', path: 'manzil', formats: [ 'webp' ] },
    { name: 'manzil_solution', path: 'manzil', formats: [ 'webp' ] },
    { name: 'manzil_process', path: 'manzil', formats: [ 'webp' ] },
    { name: 'manzil_mobile_carousel_1', path: 'manzil', formats: [ 'webp' ] },
    { name: 'manzil_mobile_carousel_2', path: 'manzil', formats: [ 'webp' ] },
    { name: 'manzil_mobile_carousel_3', path: 'manzil', formats: [ 'webp' ] },
    { name: 'manzil_mobile_carousel_4', path: 'manzil', formats: [ 'webp' ] },
    { name: 'manzil_mobile_carousel_5', path: 'manzil', formats: [ 'webp' ] },

    { name: 'defient_background', path: 'defient', formats: [ 'webp', 'mobile' ] },
    { name: 'defient_hero', path: 'defient', formats: [ 'webp', 'mobile' ] },
    { name: 'defient_image0', path: 'defient', formats: [ 'webp', 'mobile' ] },
    { name: 'defient_image1', path: 'defient', formats: [ 'webp', 'mobile' ] },
    { name: 'defient_image2', path: 'defient', formats: [ 'webp', 'mobile' ] },
    { name: 'defient_image3', path: 'defient', formats: [ 'webp', 'mobile' ] },
    { name: 'defient_image4', path: 'defient', formats: [ 'webp', 'mobile' ] },
    { name: 'defient_image5', path: 'defient', formats: [ 'webp', 'mobile' ] },
    { name: 'defient_case_study_hero', path: 'defient', formats: [ 'webp' ] },
    { name: 'defient_solution', path: 'defient', formats: [ 'webp' ] },
    { name: 'defient_results', path: 'defient', formats: [ 'webp' ] },
    { name: 'defient_carousel_bg', path: 'defient', formats: [ 'webp' ] },
    { name: 'defient_carousel_1', path: 'defient', formats: [ 'webp' ] },
    { name: 'defient_carousel_2', path: 'defient', formats: [ 'webp' ] },
    { name: 'defient_carousel_3', path: 'defient', formats: [ 'webp' ] },
    { name: 'defient_carousel_4', path: 'defient', formats: [ 'webp' ] },
    { name: 'defient_challenge_bg', path: 'defient', formats: [ 'webp' ] },

    { name: 'dropverse_hero', path: 'dropverse', formats: [ 'webp' ] },
    { name: 'dropverse_solution', path: 'dropverse', formats: [ 'webp' ] },
    { name: 'dropverse_process', path: 'dropverse', formats: [ 'webp' ] },
    { name: 'dropverse_carousel_bg', path: 'dropverse', formats: [ 'webp' ] },
    { name: 'dropverse_carousel_1', path: 'dropverse', formats: [ 'webp' ] },
    { name: 'dropverse_carousel_2', path: 'dropverse', formats: [ 'webp' ] },
    { name: 'dropverse_carousel_3', path: 'dropverse', formats: [ 'webp' ] },
    { name: 'dropverse_carousel_4', path: 'dropverse', formats: [ 'webp' ] },
    { name: 'dropverse_carousel_5', path: 'dropverse', formats: [ 'webp' ] },

    { name: 'poppy_case_study_hero', path: 'poppy', formats: [ 'webp' ] },
    { name: 'poppy_solution', path: 'poppy', formats: [ 'webp' ] },
    { name: 'poppy_process', path: 'poppy', formats: [ 'webp' ] },
    { name: 'poppy_carousel_bg', path: 'poppy', formats: [ 'webp' ] },
    { name: 'poppy_carousel_1', path: 'poppy', formats: [ 'webp' ] },
    { name: 'poppy_carousel_2', path: 'poppy', formats: [ 'webp' ] },
    { name: 'poppy_carousel_3', path: 'poppy', formats: [ 'webp' ] },
    { name: 'poppy_carousel_4', path: 'poppy', formats: [ 'webp' ] },
    { name: 'poppy_carousel_5', path: 'poppy', formats: [ 'webp' ] },
    { name: 'poppy_challenge_bg', path: 'poppy', formats: [ 'webp' ] },
    { name: 'poppy_results_bg', path: 'poppy', formats: [ 'webp', 'mobile' ] },

    { name: 'chillrx_case_study_hero', path: 'cprx', formats: [ 'webp' ] },
    { name: 'chillrx_challenge_bg', path: 'cprx', formats: [ 'webp' ] },
    { name: 'chillrx_solution', path: 'cprx', formats: [ 'webp' ] },
    { name: 'chillrx_carousel_bg', path: 'cprx', formats: [ 'webp' ] },
    { name: 'chillrx_carousel_1', path: 'cprx', formats: [ 'webp' ] },
    { name: 'chillrx_carousel_2', path: 'cprx', formats: [ 'webp' ] },
    { name: 'chillrx_carousel_3', path: 'cprx', formats: [ 'webp' ] },
    { name: 'chillrx_carousel_4', path: 'cprx', formats: [ 'webp' ] },
    { name: 'chillrx_carousel_5', path: 'cprx', formats: [ 'webp' ] },
    { name: 'chillrxgraph', path: 'cprx', formats: [ 'webp', 'mobile' ] },

    /* Work */
    { name: 'dropverse_tile', path: 'dropverse', formats: [ 'webp', 'mobile' ] },
    { name: 'auggy_tile', path: 'auggy', formats: [ 'webp', 'mobile' ] },
    { name: 'cprx_tile', path: 'cprx', formats: [ 'webp', 'mobile' ] },
    {
        name: 'dealer-methods_tile',
        path: 'dealer-methods',
        formats: [ 'webp', 'mobile' ],
    },
    { name: 'manzil_tile', path: 'manzil', formats: [ 'webp', 'mobile' ] },
    { name: 'defient_tile', path: 'defient', formats: [ 'webp', 'mobile' ] },
    { name: 'poppy_tile', path: 'poppy', formats: [ 'webp', 'mobile' ] },

    /* Team */
    { name: 'team_hero', path: 'team', formats: [ 'webp', 'mobile' ] },
    { name: 'team_carousel0', path: 'team', formats: [ 'webp' ] }, //optimize after re-enabled
    { name: 'team_carousel1', path: 'team', formats: [ 'webp' ] }, //optimize after re-enabled
    { name: 'team_carousel2', path: 'team', formats: [ 'webp' ] }, //optimize after re-enabled
    { name: 'team_carousel3', path: 'team', formats: [ 'webp' ] }, //optimize after re-enabled

    /* Misc */
    { name: 'right-arrow', path: 'icons', formats: [ 'webp' ] },
    { name: 'lock', path: 'icons', formats: [ 'webp' ] },
    { name: 'star', path: 'icons', formats: [ 'webp' ] },
];

for (let i = 0; i < imagesWithAdditionalFormats.length; i++) 
{
    const { name, path, formats } = imagesWithAdditionalFormats[i];

    const hasWebp = formats?.includes('webp');
    const hasMobile = formats?.includes('mobile');

    if (hasWebp) 
    {
        require(`./${path}/${name}.png.webp`).default;
    }

    if (hasMobile) 
    {
        require(`./${path}/mobile/${name}-mobile.png`).default;
    }

    if (hasWebp && hasMobile) 
    {
        require(`./${path}/mobile/${name}-mobile.png.webp`).default;
    }
}

export default IMAGES;
