import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TABLET } from 'core/utilities';

export const useIsDesktop = (): [ boolean, Dispatch<SetStateAction<boolean>> ] =>
{
    const [ isDesktop, setIsDesktop ] = useState<boolean>(window.innerWidth > TABLET.window.maxWidth);

    useEffect(() => 
    {
        setIsDesktop(window.innerWidth > TABLET.window.maxWidth);

        const handleResize = () => 
        {
            setIsDesktop(window.innerWidth > TABLET.window.maxWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return [ isDesktop, setIsDesktop ];
};