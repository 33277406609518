export const DeviceSize = {
    mobileS  : 322,
    mobileM  : 374,
    mobileL  : 410,
    tablet   : 767,
    tabletL  : 835,
    laptopS  : 1024,
    laptopM  : 1366,
    laptopL  : 1420,
    desktopS : 1600,
    desktopM : 1920,
    desktopL : 2560
};

export const MediaQuery = {
    mobileS  : `(min-width: ${DeviceSize.mobileS}px)`,
    mobileM  : `(min-width: ${DeviceSize.mobileM}px)`,
    mobileL  : `(min-width: ${DeviceSize.mobileL}px)`,
    tablet   : `(min-width: ${DeviceSize.tablet}px)`,
    tabletL  :  `(min-width): ${DeviceSize.tabletL}px`,
    laptopS  : `(min-width: ${DeviceSize.laptopS}px)`,
    laptopL  : `(min-width: ${DeviceSize.laptopL}px)`,
    laptopM  : `(min-width: ${DeviceSize.laptopM}px)`,
    desktopS : `(min-width: ${DeviceSize.desktopS}px)`,
    desktopM : `(min-width: ${DeviceSize.desktopM}px)`,
    desktopL : `(min-width: ${DeviceSize.desktopL}px)`,
    mobileSMax  : `(max-width: ${DeviceSize.mobileS}px)`,
    mobileMMax  : `(max-width: ${DeviceSize.mobileM}px)`,
    mobileLMax  : `(max-width: ${DeviceSize.mobileL}px)`,
    tabletMax   : `(max-width: ${DeviceSize.tablet}px)`,
    tabletLMax  : `(max-width): ${DeviceSize.tabletL}px`,
    laptopSMax  : `(max-width: ${DeviceSize.laptopS}px)`,
    laptopLMax  : `(max-width: ${DeviceSize.laptopL}px)`,
    laptopMMax  : `(max-width: ${DeviceSize.laptopM}px)`,
    desktopSMax : `(max-width: ${DeviceSize.desktopS}px)`,
    desktopMMax : `(max-width: ${DeviceSize.desktopM}px)`,
    desktopLMax : `(max-width: ${DeviceSize.desktopL}px)`,
};