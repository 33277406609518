import React, { useState, useEffect } from 'react';

interface Props 
{
    ref            : React.RefObject<HTMLDivElement>;
    onScrollUp?    : () => void;
    onScrollDown?  : () => void;
    onScrollLeft?  : () => void;
    onScrollRight? : () => void;
}

type ScrollState = {
  x : number;
  y : number;
};

export const useScrollPosition = ({
    ref,
    onScrollUp,
    onScrollDown,
    onScrollLeft,
    onScrollRight
} : Props) : ScrollState => 
{
    const [ scroll, setScroll ] = useState<ScrollState>({
        x : ref.current?.scrollLeft ?? 0,
        y : ref.current?.scrollTop ?? 0
    });

    useEffect(() => 
    {
        const onScroll = () => 
        {
            setScroll((prevState : ScrollState) => 
            {
                const x = prevState.x;
                const y = prevState.y;
                const deltaX = ref.current?.scrollLeft ?? 0;
                const deltaY = ref.current?.scrollTop ?? 0;

                if (x > deltaX)
                {
                    onScrollLeft && onScrollLeft();
                }
                else if (x < deltaX)
                {
                    onScrollRight && onScrollRight();
                }

                if (y > deltaY)
                {
                    onScrollUp && onScrollUp();
                }
                else if (y < deltaY)
                {
                    onScrollDown && onScrollDown();
                }

                return { 
                    x : deltaX, 
                    y : deltaY
                };
            });
        };

        ref.current && ref.current.addEventListener('scroll', onScroll);
        return () => ref.current?.removeEventListener('scroll', onScroll);
    }, [ ref ]);

    return scroll;
};