import React, { useContext, useReducer } from 'react';
import { initialState, AuthReducer, AuthState, AuthAction } from './reducer';
import { Palette } from 'core/config';

export interface AppContextData {
    isDesktop: boolean | null,
    setIsDesktop: (isDesktop: boolean) => void,
    scrollbarWidth: number,
    navbarIsExpanded: boolean,
    setNavbarIsExpanded: (navbarIsExpanded: boolean) => void,
    backgroundColor: string,
    setBackgroundColor: (color: string) => void,
}

const AuthStateContext    = React.createContext<AuthState>(initialState);
const AuthDispatchContext = React.createContext<React.Dispatch<AuthAction>>(() => null);

export const AppContext   = React.createContext<AppContextData>({ 
    isDesktop: null,
    setIsDesktop: () => null,
    scrollbarWidth: 0,
    navbarIsExpanded: false,
    setNavbarIsExpanded: () => null,
    backgroundColor: Palette.WHITE,
    setBackgroundColor: () => null,
});

export const useAuthorization = () : AuthState =>
{
    const context = useContext(AuthStateContext);
    return context;
};

export const useAuthorizationDispatch = () : React.Dispatch<AuthAction> =>
{
    const context = useContext(AuthDispatchContext);
    return context;
};

export const useAppContext = () : AppContextData =>
{
    const context = useContext(AppContext);
    return context;
};

export const AuthProvider: React.FunctionComponent = ({ children }) => 
{
    const [ user, dispatch ] = useReducer<React.Reducer<AuthState, AuthAction>>(AuthReducer, initialState);

    return (
        <AuthStateContext.Provider value={user}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    );
};