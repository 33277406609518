import React, { useRef } from 'react';
import styled from 'styled-components';
import Navbar, { SCROLL_BEHAVIOR } from './Navbar';
import Footer from './Footer';
import { HomePage } from 'view/pages/Home';
import { CTGCBanner } from '../common/CTGCBanner';

type Props = React.HTMLAttributes<HTMLDivElement>;

export const SplashLayout : React.FunctionComponent<Props> = () =>
{
    const ref = useRef<HTMLDivElement>(null);

    return (
        <>
            <Navbar 
                scrollBehavior={{ 
                    mobile: SCROLL_BEHAVIOR.OPAQUE_AFTER_SCROLL 
                }} 
            />
            <Container id="container" ref={ref}>
                <HomePage />
                <Footer isFullHeight={true} />
                <CTGCBanner />
            </Container>
        </>
    );
};

const Container = styled.div`
    position: relative;
    width : 100%;
    overflow-x: hidden;
`;