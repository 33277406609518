import React from 'react';
import styled, { keyframes } from 'styled-components';

export const LoadingSpinner : React.FunctionComponent = () =>
{
    return (
        <Container>
            <Spinner />
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const Rotate = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(359deg); }
`;

const Spinner = styled.div`
    z-index: 1000;

    width: 32px;
	height: 32px;
	clear: both;
	margin: 20px auto;

    border: 4px gray solid;
    border-top: 4px black solid;
    border-radius: 50%;
    animation: ${Rotate} .6s infinite linear;
`;