import { Palette } from 'core/config';
import { Data } from './interfaces';
import { 
    NODE_ENV,
    noop
} from './constants';

type ConsoleFn = (
    title : string,
    style : string,
    data  : Data
) => void;

interface ConsoleWrapper
{
    fn   : ConsoleFn;

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    args : [ string, string, any ];
}

const logWithTime = ({ fn, args } : ConsoleWrapper) : void =>
{
    const [
        title,
        style,
        data 
    ] = args;
    const time = (new Date()).toLocaleTimeString();

    fn.apply(console, [
        `%c [${time}] ${title} %o`,
        style,
        data
    ]);
};

const webVitals = (data : Data) : void => 
{
    logWithTime({
        fn   : console.info,
        args : [
            'Web vitals:',
            `background-color: ${Palette.COD_GREY}; color: ${Palette.WHITE};`,
            data
        ]
    });
};

const errorBoundary = (data : Data) => 
{
    logWithTime({
        fn   : console.error,
        args : [
            'Error boundary reached:',
            `background-color: ${Palette.RED}; color: ${Palette.WHITE};`,
            data
        ]
    });
};

const performance = (id : string, data : Data) => 
{
    console.group();
    logWithTime({
        fn   : console.info,
        args : [
            'Profiler:',
            `background-color: ${Palette.PACIFIC_BLUE}; color: ${Palette.WHITE};`,
            id
        ]
    });
    console.info(data);
    console.groupEnd();
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
type Logger = (...data: any) => void;

interface Console extends Data
{
    webVitals     : Logger;
    errorBoundary : Logger;
    performance   : Logger;
}

const enabledLogConfig = {
    webVitals     : true,
    errorBoundary : true,
    performance   : true
} as { [ key : string] : boolean };

export const log = (() : Console => 
{
    const fns = {
        webVitals,
        errorBoundary,
        performance
    } as Console;

    for (const fn of Object.keys(enabledLogConfig))
    {
        if (NODE_ENV === 'production' || !enabledLogConfig[fn])
        {
            fns[fn] = noop;
        }
    }

    return fns;
})();
