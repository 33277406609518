import React, { useEffect, useRef } from 'react';

// Colour palette from useRenderSphere
const COLOURS = [
    'rgba(255, 0, 102, 0.006)',
    'rgba(255, 0, 72, 0.012)',
    'rgba(255, 0, 43, 0.018)',
    'rgba(255, 0, 13, 0.024)',
    'rgba(255, 17, 0, 0.03)',
    'rgba(255, 47, 0, 0.036)',
    'rgba(255, 77, 0, 0.042)',
    'rgba(255, 106, 0, 0.048)',
    'rgba(255, 136, 0, 0.054)',
    'rgba(255, 166, 0, 0.06)',
    'rgba(255, 196, 0, 0.066)',
    'rgba(255, 225, 0, 0.072)',
    'rgba(255, 255, 0, 0.078)',
    'rgba(225, 255, 0, 0.084)',
    'rgba(196, 255, 0, 0.09)',
    'rgba(166, 255, 0, 0.096)',
    'rgba(136, 255, 0, 0.102)',
    'rgba(106, 255, 0, 0.108)',
    'rgba(77, 255, 0, 0.114)',
    'rgba(47, 255, 0, 0.12)',
    'rgba(17, 255, 0, 0.126)',
    'rgba(0, 255, 13, 0.132)',
    'rgba(0, 255, 43, 0.138)',
    'rgba(0, 255, 72, 0.144)',
    'rgba(0, 255, 102, 0.15)',
    'rgba(0, 255, 132, 0.156)',
    'rgba(0, 255, 162, 0.162)',
    'rgba(0, 255, 191, 0.168)',
    'rgba(0, 255, 221, 0.174)',
    'rgba(0, 255, 251, 0.18)',
    'rgba(0, 230, 255, 0.186)',
    'rgba(0, 200, 255, 0.192)',
    'rgba(0, 170, 255, 0.198)',
    'rgba(0, 140, 255, 0.204)',
    'rgba(0, 111, 255, 0.21)',
    'rgba(0, 81, 255, 0.216)',
    'rgba(0, 51, 255, 0.222)',
    'rgba(0, 21, 255, 0.228)',
    'rgba(9, 0, 255, 0.234)',
    'rgba(38, 0, 255, 0.24)',
    'rgba(68, 0, 255, 0.246)',
    'rgba(98, 0, 255, 0.252)',
    'rgba(128, 0, 255, 0.258)',
    'rgba(157, 0, 255, 0.264)',
    'rgba(187, 0, 255, 0.27)'
];

// Line dash configuration(l, d, d)
// l = line; d = dash;
// This could be refactored, but we'll leave this for now 
const LINE_CONFIG = [
    {
        pattern: 'l',
        colour: 'rgba(255,153,204,255)'
    },
    {
        pattern: 'd',
        colour: 'rgba(244,156,205,255)'
    },
    {
        pattern: 'd',
        colour: 'rgba(233,160,207,255)'
    },
    {
        pattern: 'l',
        colour: 'rgba(222,163,208,255)'
    }, 
    {
        pattern: 'd',
        colour: 'rgba(200,169,211,255)'
    }, 
    {
        pattern: 'd',
        colour: 'rgba(289,173,212,255)'
    }, 
    {
        pattern: 'l',
        colour: 'rgba(171,178,214,255)'
    }, 
    {
        pattern: 'd',
        colour: 'rgba(167,179,215,255)'
    }, 
    {
        pattern: 'd',
        colour: 'rgba(156,183,216,255)'
    }, 
    {
        pattern: 'l',
        colour: 'rgba(145,186,217,255)'
    },  
    {
        pattern: 'd',
        colour: 'rgba(132,189,219,255)'
    },  
    {
        pattern: 'd',
        colour: 'rgba(121,193,220,234)'
    },  
    {
        pattern: 'l',
        colour: 'rgba(113,196,221,255)'
    },  
    {
        pattern: 'd',
        colour: 'rgba(91,202,224,255)',
    }, 
    {
        pattern: 'd',
        colour: 'rgba(70,209,232,11)', 
    },
    {
        pattern: 'l',
        colour: 'rgba(83,206,226,114)',
    }, 
    {
        pattern: 'd',
        colour: 'rgba(58,213,229,212)',
    }, 
    {
        pattern: 'd',
        colour: 'rgba(55,213,228,219)',
    }, 
    {
        pattern: 'l',
        colour: 'rgba(28,221,231,238)',
    }, 
];

export const useRenderWaves = (ref : React.RefObject<HTMLCanvasElement>, renderHeroSection : boolean) : void => 
{
    const animateRef = useRef<number>(0);

    useEffect(() => 
    {
        if (ref.current) 
        {
            const canvas  = ref.current;
            const ctx     = canvas.getContext('2d', { alpha: false });

            const draw = () => 
            {
                if (!renderHeroSection || !ctx) return;

                //update canvas size
                const height = ~~(canvas.clientHeight);
                const width = ~~(canvas.clientWidth);
                canvas.height = height;
                canvas.width = width;

                const time = performance.now() / 1000; // Returns time elapsed since origin converted into 1 second
                const dividedHeight  = height / 2;     // Canvas height over two => Amplitude: Volume
                const pixelStep      = 3;              // Step itteration for creating waves horizontally     
                const amplitude      = 1.85;           // Controls amplitude of the wave

                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, width, height);
                
                for (let i = 0; i < LINE_CONFIG.length; i++) // Loop over line configuration
                {
                    const { pattern } = LINE_CONFIG[i];

                    const linePattern = pattern === 'd' ? [ 1, 8 ] : [];     // Set either a line or dash pattern

                    ctx.lineCap       = 'round';                             // Convert line dashes into "circles"
                    ctx.strokeStyle   = COLOURS[i * 6];                      // Set rgba stroke colour based on colour palette
                    ctx.lineWidth     = (LINE_CONFIG.length - i + 1) * 0.20; // Fades line width based on index

                    ctx.setLineDash(linePattern);  // Set (l, d, d) dash pattern or plain line
                    ctx.beginPath();               // Draw a new path
                    ctx.moveTo(-0, dividedHeight); // Where to start drawing

                    for (let j = 0; j < width; j += pixelStep) // Create wave positions from left side to right
                    {
                        const phi1 = Math.sin(j / 50 - time / 50 - i / 118); // Fine tunes wave oscillation 
                        const phi2 = Math.sin(j / 25 - (i + time) / 5);      // Control frequency / direction of the wave
                        const distanceBetweenLines = i * 15;                 

                        const x = Math.cos(i / 10) + j + 0.004 * j * j;
                        const y = ~~(dividedHeight + j / 2 / amplitude * phi1 + (distanceBetweenLines) * phi2);

                        ctx.lineTo(x, y); // Where to draw waves
                    }

                    ctx.stroke(); // Draw waves
                }
                
                animateRef.current = requestAnimationFrame(draw);
            };

            if(renderHeroSection) 
            {
                // Animate waves if they are within viewport
                animateRef.current = requestAnimationFrame(draw);
            } 
            else 
            {
                // Stop waves animation
                cancelAnimationFrame(animateRef.current);
            }

            // Resets wave animation when a screen size is adjusted
            const handleResize = () => 
            {
                cancelAnimationFrame(animateRef.current);

                animateRef.current = requestAnimationFrame(draw);
            };

            // Add resize event listener
            window.addEventListener('resize', handleResize);

            return () => 
            {
                // Cancel animation frame after canvas component unmounts
                cancelAnimationFrame(animateRef.current);
                // Remove resize event listener
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [ ref, renderHeroSection ]);
};