import React from 'react';
import MaterialModal, { ModalOwnProps } from '@mui/material/Modal';
import styled from 'styled-components';
import IMAGES from 'assets/images';
import { MediaQuery } from 'core/config';

export const Modal: React.FunctionComponent<ModalOwnProps> = ({
    open,
    onClose,
    children,
}) => 
{
    return (
        <Container
            open={open}
            onClose={onClose}
        >
            <ContentWrapper>
                <Header>
                    <ImageButton onClick={(e) => onClose?.(e, 'escapeKeyDown')}>
                        <Image src={IMAGES.closeIcon}/>
                    </ImageButton>
                </Header>
                <Content>
                    {children}
                </Content>
            </ContentWrapper>
        </Container>
    );
};

const Container = styled(MaterialModal)`
    &.MuiModal-root {
        z-index: 20000;
        display: flex;
        align-items: center;
        padding: 16px;

        @media ${MediaQuery.tablet} {
            padding: 16px 72px 16px 72px;
        }

        @media ${MediaQuery.laptopS} {
            padding: 16px 300px 16px 300px;
        }

        @media ${MediaQuery.desktopM} {
            padding: 16px 400px 16px 400px;
        }
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #FFFFFF;
    border-radius: 10px;
    max-height: 100%;
    overflow-y: auto;
`;

const Header = styled.div`
    padding: 24px 24px 0px 24px;
    display: flex;
    justify-content: flex-end;
`;

const Content = styled.div`
    padding: 24px 16px 36px 16px;

    @media ${MediaQuery.tablet} {
        padding: 24px 36px 36px 36px;
    }
`;

const ImageButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    
    transition: all 0.2s ease-in-out;

    &:hover {
        opacity: 0.5;
    }
`;

const Image = styled.img<{ src: string }>`
    width: 16px;
    height: 16px;
`;