import { Grid, Menu, MenuItem, Typography } from '@mui/material';
import IMAGES from 'assets/images';
import styled from 'styled-components';

interface ProductsMenuProps {
  anchorEl: any;
  onClose: () => void;
}

const ProductsMenu: React.FunctionComponent<ProductsMenuProps> = ({
    anchorEl,
    onClose,
}) => 
{
    const menuItemStyles = {
        fontFamily: 'Poppins-Regular',
        mx: 3,
        my: 1,
        p: 2,
        borderRadius: '16px',
        '&:hover': {
            background: '#F8F8F8',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'inherit',
            '&:hover': {
                background: '#F8F8F8',
            },
        },
    };

    const links = [
        {
            label: 'Speer Labs',
            description: ' ',
            href: '/lab',
            target: undefined,
        },
        {
            label: 'Onpath',
            description: ' ',
            href: 'https://getonpath.io',
            target: '_blank',
        },
        {
            label: 'Password Generator',
            description: ' ',
            href: '/password-generator',
            target: undefined,
        },
        {
            label: 'Color Contrast Checker',
            description: ' ',
            href: '/color-contrast-checker',
            target: undefined,
        },
    ];
    return (
        <>
            <Menu
                sx={{
                    mt: '45px',
                    zIndex: 10001,
                }}
                slotProps={{
                    paper: {
                        sx: {
                            width: '400px',
                            borderRadius: '20px',
                        },
                    },
                }}
                MenuListProps={{
                    sx: {
                        py: 4,
                    },
                }}
                autoFocus
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={onClose}
            >
                {links.map((link, index) => (
                    <MenuItem
                        key={`products-menu-links-${index}`}
                        component="a"
                        target={link?.target}
                        sx={menuItemStyles}
                        href={link.href}
                    >
                        <Grid container spacing={1}>
                            <Grid
                                container
                                item
                                justifyContent={'space-between'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >
                                <Grid item>
                                    <Typography
                                        fontSize={17}
                                        sx={{ fontFamily: 'Poppins-Regular' }}
                                    >
                                        {link.label}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        background: 'white',
                                        borderRadius: '50%',
                                        width: '30px',
                                        height: '30px',
                                        padding: '4px 8px',
                                    }}
                                >
                                    <ArrowImg src={IMAGES.arrowUpRight} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    fontSize={16}
                                    sx={{
                                        fontFamily: 'Poppins-Light',
                                    }}
                                >
                                    {link.description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

const ArrowImg = styled.img`
  width: 14px;
  height: 14px;
`;

export default ProductsMenu;
