import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { MediaQuery } from 'core/config';
import { gsap, ScrollTrigger } from 'gsap/all';
import { useGSAP } from '@gsap/react';

import ServiceCard from 'view/components/common/ServiceCard';
import { cardList } from '../data/services.json';

gsap.registerPlugin(useGSAP, ScrollTrigger);

export const Services: React.FunctionComponent = () => 
{
    const scrollContainerRef: any = useRef();

    useEffect(() => 
    {
        const ctx = gsap.context(() => 
        {
            const container: any = gsap.utils.toArray('.container')[0];
            if (container) 
            {
                const card: any = gsap.utils.toArray('.service-card')[0];
                const mm = gsap.matchMedia(container);

                mm.add(
                    '(min-width: 767px)',
                    () => 
                    {
                        gsap.to(container, {
                            x: () =>
                                `-${
                                    container.scrollWidth -
                  window.innerWidth +
                  card.clientWidth * 2
                                }px`,
                            ease: 'power1.inOut',
                            invalidateOnRefresh: true,
                            scrollTrigger: {
                                invalidateOnRefresh: true,
                                trigger: scrollContainerRef.current,
                                start: 'top 20%',
                                end: '+=2000',
                                scrub: 0.2,
                                pin: true,
                                pinSpacing: true,
                            },
                        });
                    },
                    { scope: scrollContainerRef }
                );
            }
        });

        return () => ctx.revert();
    }, [ scrollContainerRef.current ]);

    return (
        <ScrollContainer ref={scrollContainerRef}>
            <ServiceCardsContainer className="container">
                {cardList.map((card) => (
                    <ServiceCard key={`service-${card.number}-${card.title}`} {...card} />
                ))}
            </ServiceCardsContainer>
        </ScrollContainer>
    );
};

const ScrollContainer = styled.div`
  display: flex;
  padding: 4px;

  @media ${MediaQuery.mobileS} {
    overflow: scroll;
  }
  @media ${MediaQuery.tablet} {
    overflow: hidden;
    height: 100vh;
  }
`;

const ServiceCardsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 32px;
  margin: 4rem 6rem;

  @media ${MediaQuery.mobileS} {
    pointer-events: none;
    padding-right: 16px;
    margin: 0 16px;
  }

  @media ${MediaQuery.tablet} {
    pointer-events: painted;
    padding: 4px;
    margin: 0 5rem;
  }

  @media ${MediaQuery.laptopS} {
    pointer-events: painted;
    padding: 4px;
    margin: 0 7rem;
  }
`;
