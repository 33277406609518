import React from 'react';
import styled from 'styled-components';
import IMAGES from 'assets/images';
import { Palette, MediaQuery } from 'core/config';
import { ImageWrapper } from 'view/components/common';
import { Section } from '../Section';
import Phone from './Phone';

interface Props 
{
    intersected?     : boolean;
    toggleClassName? : string;
}

export const DealerMethods : React.FunctionComponent<Props> = ({ intersected = true, toggleClassName = '' }) => 
{
    return(
        <Section
            title={'Dealer Methods'}
            subTitle={'Optimizing The \nFleet Management \nSystem'}
            logo={{ url: IMAGES.dealerMethodsLogo, width: 144, height: 12 }}
            colour={Palette.SAPPHIRE}
            backgroundColour={Palette.WHITE}
            zIndexPos={3000}
            justifyContent={'flexEnd'}
            toggleClassName={toggleClassName}
            intersected={intersected}
        >
            <Container>
                <ImageWrapper src={IMAGES.mapBackground} hasMobileSrc={true}>
                    <Image 
                        src={IMAGES.mapBackground} 
                        width='649' 
                        height='600' 
                        alt="Dealer Methods Map" 
                    />
                </ImageWrapper>
                <PhonesContainer>
                    <Phone 
                        id="pick-car-phone"
                        intersected={intersected}
                        phoneImage={IMAGES.pickCarPhone}
                        popupImage={IMAGES.distancePhone}
                        delay={0.1}
                        phoneCss={'position: absolute; top: 25%; right: 60%;'}
                        phoneCssLarge={'right: 50%;'}
                        popupCss={'left: 32%; width: 19%; top: 48%; bottom: none;'}
                    />
                    <Phone 
                        id="welcome-back-phone"
                        intersected={intersected}
                        phoneImage={IMAGES.welcomeBackPhone}
                        popupImage={IMAGES.schedulePhone}
                        delay={0.15}
                    />
                    <Phone 
                        id="reservations-phone"
                        intersected={intersected}
                        phoneImage={IMAGES.reservationsPhone}
                        popupImage={IMAGES.pickupPhone}
                        delay={0.25}
                        popupCss={'bottom: 44%; left: 28.5%; width: 24%;'}
                    />
                </PhonesContainer>
            </Container>  
        </Section>
    );
};

const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 4em 0;

    .render-dm-content {
        transform: translateY(0);
        opacity: 1; 
    }

    @media ${MediaQuery.laptopS} {
        padding: 0;
    }
`;

const Image = styled.img`
    position: relative;
    right: 0;

    width: 100%;
    height: auto;

    @media ${MediaQuery.laptopS} {
        right: 300px;
    }
    @media ${MediaQuery.laptopS} {
        right: 350px;
    }
    @media ${MediaQuery.laptopL} {
        right: 0;
        min-width: calc(100vw);
        margin-left: calc(-100vw * 0.125 - 325px);
    }
    @media ${MediaQuery.desktopS} {
        margin-left: calc(-100vw * 0.125 - 375px);
    }
`;

const PhonesContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 175%;
    margin-left: 15%;
    max-width: 1200px;

    @media ${MediaQuery.laptopS} {
        width: 100%;
        margin-left: 0em;
    }
`;