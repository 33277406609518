import React from 'react';
import styled from 'styled-components';
import { MediaQuery, Palette } from 'core/config';
import { useAppContext } from 'core/context';

export const FixedButton: React.FunctionComponent = ({ children }) => 
{
    const { navbarIsExpanded } = useAppContext();

    const handleClick = () => 
    {
        const container = document.querySelector('#container');
        window?.scrollTo({
            left: 0,
            top: container?.scrollHeight,
            behavior: 'smooth'
        });
    };

    return !navbarIsExpanded ? <Container onClick={handleClick}> {children} </Container> : null;
};


const Container = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 167px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 500px;

    border: 2px solid ${Palette.DARK_GRAY};
    background-color: ${Palette.DARK_GRAY};
    color: ${Palette.WHITE};

    font-family: 'Poppins-Light';
    font-size: 17px;

    cursor: pointer;
    transition: opacity 1s ease-in-out;

    :hover {
        background-color: ${Palette.WHITE};
        color: ${Palette.DARK_GRAY};
    }

    @media ${MediaQuery.tablet} {
        font-family: 'Poppins-Regular';
    }
`;

//const Container = styled.button`
//    position: relative;
//    width: 133px;
//    height: 40px;
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    background: ${Palette.NAVAJO_WHITE} 0% 0% no-repeat padding-box;
//    border: 0;
//    border-radius: 50px;
//    font-family: 'Poppins-Regular';
//    font-size: 12px;
//    text-transform: uppercase;
//    cursor: pointer;
//    @media ${MediaQuery.tablet} {
//        margin: auto;
//        width: 243px;
//        height: 74px;
//        font-size: 21px;
//    }
//`;