import IMAGES from 'assets/images';
import { useHistory } from 'react-router';
import { MediaQuery, Palette } from 'core/config';
import React from 'react';
import styled from 'styled-components';

const FeaturedAnimatedCard: React.FC<any> = ({ item }) => 
{
    const history = useHistory();

    const onCardClick = (link: string) => 
    {
        if (link.includes('http')) 
        {
            window.open(link, '_blank');
        }
        else 
        {
            history.push(link);
        }
    };

    return (
        <CardWrapper>
            <CardContainer onClick={() => onCardClick(item.url)}>
                <CardImage src={IMAGES[item.imageKey]} />
                <CardContent>
                    <StackContainer>
                        {item.techStack.map((item: string, index: number) => (
                            <TeckStackItem key={item + index}> {item}</TeckStackItem>
                        ))}
                    </StackContainer>
                    <FeaturedItemTitle>{item.title}</FeaturedItemTitle>
                    <FeaturedItemDesc>{item.desc}</FeaturedItemDesc>
                </CardContent>
            </CardContainer>
        </CardWrapper>
    );
};

const CardWrapper = styled.div`
overflow: hidden;
`;

const CardContainer = styled.div`
  display: none;
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 20px;
  -webkit-box-shadow:inset 0px 0px 0px 1px black;
  -moz-box-shadow:inset 0px 0px 0px 1px black;
  box-shadow:inset 0px 0px 0px 1px black;
  cursor: none;

  @media ${MediaQuery.laptopS} {
    display: flex;
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out, width 0.4s;
  object-fit: cover;
    border-radius: 17px;

  ${CardContainer}:hover & {
    transform: translateY(55%) translateX(5%);
    border-radius: 20px;
    width: 90%;
  }
`;

const CardContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 32px;
  box-sizing: border-box;
  z-index: -1;
`;

const StackContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const TeckStackItem = styled.p`
  color: ${Palette.BLACK};
  font-family: "Poppins-Light";
  font-size: 14px;
`;

const FeaturedItemTitle = styled.h4`
  color: ${Palette.BLACK};
  font-family: "Poppins-Regular";
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const FeaturedItemDesc = styled.p`
  color: ${Palette.BLACK};
  font-family: "Poppins-Light";
  font-size: 18px;
`;

export default FeaturedAnimatedCard;
