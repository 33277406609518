import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Palette, MediaQuery } from 'core/config';
import { awardsPress, colours } from '../data/awards.json';
import IMAGES from 'assets/images';

interface AwardCardProps {
    title: string;
    description: string;
    previewImageKey: string;
    backgroundColor: string;
    previewBackgroundColor: string;
    publisherIconKey: string;
    src: string
}

const AwardCard: React.FunctionComponent<AwardCardProps> = ({ title, description, previewImageKey, publisherIconKey, src, backgroundColor, previewBackgroundColor }) => 
{
    const containerRef = useRef<HTMLDivElement>(null);
    const [ isHovered, setIsHovered ] = useState<boolean>(false);
    const [ { x, y }, setMousePosition ] = useState({ x: 0, y: 0 });

    const handleAwardClick = (event: React.MouseEvent<HTMLElement>, link: string) => 
    {
        event.preventDefault();
    
        if (!link) return;    
        window.open(link, '_blank');
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => 
    {
        const container = containerRef.current;
        if (!container) return;
        const bounds = container.getBoundingClientRect();

        const x = event.clientX - bounds.x;
        const y = event.clientY - bounds.y;

        setMousePosition({ x, y });
    };

    return (
        <Award
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onMouseMove={handleMouseMove}
            onClick={(e) => {handleAwardClick(e, src);}} 
            hoverColor={backgroundColor}
            ref={containerRef}>
            <AwardTitleContainer>
                <Icon src={IMAGES[publisherIconKey]} />
                <AwardTitle>{title}</AwardTitle>
            </AwardTitleContainer>

            <AwardDescription>
                {description}
            </AwardDescription>

            <Button onClick={(e) => {handleAwardClick(e, src);}}>Read full article</Button>
            <PreviewImage src={IMAGES[previewImageKey]} isVisible={previewImageKey != '' && isHovered} backgroundColor={previewBackgroundColor} x={x} y={y} />
        </Award>
    );
};

const defaultLinesVisibleState = new Array(awardsPress.length).fill(true);
defaultLinesVisibleState[defaultLinesVisibleState.length - 1] = false;

export const Awards: React.FunctionComponent = () => 
{
    const [ isLinesVisible, setIsLinesVisible ] = useState<boolean[]>(defaultLinesVisibleState);

    const handleMouseHover = (index: number, isEnter: boolean) => 
    {
        const newIsLinesVisible = [ ...defaultLinesVisibleState ];
        newIsLinesVisible[index] = !isEnter;
        newIsLinesVisible[index - 1 || 0] = !isEnter;
        newIsLinesVisible[newIsLinesVisible.length - 1] = false;
        setIsLinesVisible(newIsLinesVisible);
    };
    

    return (
        <Container>
            <InnerContainer>
                <TitleContainer>
                    <Title>{'Awards & Press'}</Title>
                </TitleContainer>

                <ContentContainer>
                    {
                        awardsPress.map(({ title, description, publisherIconKey, src, previewImageKey }, index) => 
                            <AwardContainer 
                                key={`home-awards-${index}`}
                                onMouseEnter={() => handleMouseHover(index, true)}
                                onMouseLeave={() => handleMouseHover(index, false)}
                            >
                                <AwardCard 
                                    title={title} 
                                    description={description} 
                                    publisherIconKey={publisherIconKey} 
                                    src={src} 
                                    previewImageKey={previewImageKey}
                                    backgroundColor={colours[index % colours.length].background}
                                    previewBackgroundColor={colours[index % colours.length].previewBackground} />
                                <LineBreak isVisible={isLinesVisible[index]} />
                            </AwardContainer>
                        )
                    }
                </ContentContainer>
            </InnerContainer>
        </Container>
    );
};

const Container = styled.div`
    @media ${MediaQuery.mobileS} {
        padding: 0px 16px 100px 16px;
    }

    @media ${MediaQuery.tablet} {
        padding: 0px 100px 100px 5rem;
    }

    @media ${MediaQuery.laptopL} {
        padding: 0px 170px 100px 7rem;
    }
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media ${MediaQuery.mobileS} {
    }
`;

const TitleContainer = styled.div`
    @media ${MediaQuery.mobileS} {
        margin-bottom: 64px;
    }

    @media ${MediaQuery.laptopS} {
        margin-bottom: 0;
    }
`;

const Title = styled.h1`
    color: ${Palette.BLACK};
    font-size: 48px;
    
    @media ${MediaQuery.mobileS} {
        font-size: calc(1.325em + 4vw);
    }
    @media ${MediaQuery.laptopS} {
        font-size: 68px;
    }
`;

const LineBreak = styled.hr<{isVisible: boolean}>`
    cursor: none;
    visibility: ${({ isVisible }) =>!isVisible && 'hidden' };
    transition: visibility 0.1s ease-in;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const AwardContainer = styled.div`
    position: relative;

    @media ${MediaQuery.mobileS} {
        pointer-events: none;
        margin: 16px 0;
    }

    @media ${MediaQuery.laptopS} {
        pointer-events: all;
        margin: 0;
    }
`;

const Award = styled.div<{hoverColor: string}>`
    display: flex;
    align-items: center;
    gap: 64px;
    transition: background-color 0.2s ease-in-out;
    cursor: none;

    @media ${MediaQuery.mobileS} {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px;
        padding: 8px;
        pointer-events: none;
        margin-bottom: 16px;

        :hover {
            background-color: transparent;
        }
    }

    @media ${MediaQuery.laptopS} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 64px;
        padding: 64px;
        pointer-events: all;
        margin-bottom: 0;

        :hover {
            background-color: ${({ hoverColor }) => hoverColor};
        }
    }
`;

const AwardTitleContainer = styled.div`
    flex: 1;
`;

const Icon = styled.img`
    height: 16px;
    margin-bottom: 8px;
`;

const AwardTitle = styled.h2`
    font-size: 16px;

    @media ${MediaQuery.laptopS} {
        font-size: 24px;
    }
`;

const AwardDescription = styled.p`
    flex: 1;
    font-size: 12px;
    font-weight: 100;

    @media ${MediaQuery.laptopS} {
        font-size: 16px;
    }`;

const Button = styled.button`
    border: 1px solid black;
    border-radius: 100px;
    background-color: transparent;
    color: black;
    padding: 10px 24px;
    font-weight: 500;
    font-size: 14px;
    font-style: normal;
    font-family: "Poppins-Regular";
    pointer-events: all;

    @media ${MediaQuery.laptopS} {
        display: none;
    }
`;

const PreviewImage = styled.img<{ isVisible: boolean, backgroundColor: string, x: number, y: number}>`
    position: absolute;
    padding: 32px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    max-width: 30vw;
    width: auto;
    height: 40vh;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    pointer-events: none;
    transform: translate(-50%, -50%) ${({ isVisible, x, y }) => (isVisible ? `translate(${x}px, ${y}px)` : '')};
    z-index: 100;

    @media ${MediaQuery.mobileS} {
        display: none;
    }

    @media ${MediaQuery.laptopS} {
        display: block;
    }
`;