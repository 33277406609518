import { Data } from './interfaces';
import
{
    CAROUSEL_3D_CARDS_DATA,
    CURSOR_STYLES
} from './constants';

export const carousel3dHelper = (): Data =>
{
    const { IMAGES, CARD_WIDTH } = CAROUSEL_3D_CARDS_DATA;

    const totalCardsNum = IMAGES?.length | 0;
    const radius = ( CARD_WIDTH + 10) * totalCardsNum / (Math.PI * 2);
    const cameraDistance = radius + 20;
    const cardsAngles = IMAGES.map((img, index) =>
    {
        return getCardAngle(index, totalCardsNum);
    });

    return {
        ...CAROUSEL_3D_CARDS_DATA,
        totalCardsNum,
        radius,
        cameraDistance,
        cardsAngles,
        width: CARD_WIDTH
    };
};

const getCardAngle = (cardIndx: number, totalCards: number): number =>
{
    return cardIndx * (2 * Math.PI / totalCards);
};

export const onPointerOver = (): void =>
{
    document.body.style.cursor = CURSOR_STYLES.GRAB;
};
export const onPointerOut = (): void =>
{
    document.body.style.cursor = CURSOR_STYLES.DEFAULT;
};