import React, { useEffect, useState } from 'react';
import { AuthProvider, AppContext } from 'core/context';
import { AppRouter } from 'core/router';
import './index.css';
import './fonts.css';
import { useIsDesktop } from 'view/hooks';
import { Palette } from 'core/config';
import { Header } from 'view/components/common';

const App : React.FunctionComponent = () => 
{
    const [ isDesktop, setIsDesktop ] = useIsDesktop();
    const [ navbarIsExpanded, setNavbarIsExpanded ] = useState<boolean>(false);
    const [ backgroundColor, setBackgroundColor ] = useState<string>(Palette.WHITE);

    if (isDesktop && navbarIsExpanded) setNavbarIsExpanded(false);

    useEffect(() => 
    {
        const htmlEl = document.getElementsByTagName('html').item(0);
        if(htmlEl) 
        {
            htmlEl.style.transition = 'background-color 0.2s ease-in-out';
        }
    }, []);

    useEffect(() => 
    {
        const htmlEl = document.getElementsByTagName('html').item(0);
        if (htmlEl != null) 
        {
            htmlEl.style.backgroundColor = backgroundColor;
        }
    }, [ backgroundColor ]);

    const getScrollbarWidth = () : number => 
    {
        // Creating invisible container
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll'; // forcing scrollbar to appear
        document.body.appendChild(outer);

        // Creating inner element and placing it in the container
        const inner = document.createElement('div');
        outer.appendChild(inner);

        // Calculating difference between container's full width and the child width
        const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

        // Removing temporary elements from the DOM
        outer.parentNode?.removeChild(outer);

        return scrollbarWidth;
    };

    const scrollbarWidth = getScrollbarWidth();

    return (
        <AuthProvider>
            <Header
                title="Speer — Reframing the future"
                description="Speer collaborates with brands around the globe to develop innovative tech that fits the needs of their business."
            />
            <AppContext.Provider value={{
                isDesktop,
                setIsDesktop,
                scrollbarWidth,
                navbarIsExpanded,
                setNavbarIsExpanded,
                backgroundColor, 
                setBackgroundColor,
            }}>
                <AppRouter />
            </AppContext.Provider>
        </AuthProvider>
    );
};

export default App;