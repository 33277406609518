import React from 'react';
import { Helmet } from 'react-helmet-async';

interface Props {
    title: string;
    description: string;
}

export const Header : React.FunctionComponent<Props> = ({ title, description }) => 
{
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
        </Helmet>
    );
};